// Transform Neo4j data to Cytoscape format
export const transformData = (dBResult) => {
    const cytoscapeElements = {
        nodes: [],
        edges: []
    }

    dBResult
        .results[0]
        .data
        .forEach(item => {
            const [source, relationship, target] = item.row;

            // Add source node
            cytoscapeElements
                .nodes
                .push({
                    data: {
                        id: `n${source.id}`,
                        label: source.properties.name,
                        ...source.properties
                    }
                });

            // Add target node
            cytoscapeElements
                .nodes
                .push({
                    data: {
                        id: `n${target.id}`,
                        label: target.properties.name,
                        ...target.properties
                    }
                });

            // Add edge
            cytoscapeElements
                .edges
                .push({
                    data: {
                        id: `e${source.id}-${target.id}`,
                        source: `n${source.id}`,
                        target: `n${target.id}`,
                        label: relationship.type,
                        ...relationship.properties
                    }
                });
        });

    // Filter duplicate nodes
    const uniqueNodes = cytoscapeElements.nodes.filter((obj, index, self) =>
        index === self.findIndex((t) => (
            t.data.id === obj.data.id
        ))
    );
    // console.log(uniqueNodes)
    // console.log(cytoscapeElements.edges)

    // Combine unique nodes and edges
    const non_duplicate_cytoscapeElements = {
        nodes: [...uniqueNodes],
        edges: [...cytoscapeElements.edges]
    }

    return non_duplicate_cytoscapeElements
};