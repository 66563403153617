import React from 'react'
import PolicyCardContent from '../../Dashboard/DashCRUD/policyCardContent'
import { Link } from 'react-router-dom'
import { IoMdArrowDropdown } from "react-icons/io";
import { useSelector } from 'react-redux';
import { policyCardByUserID, userInfo } from '../../../redux/Selectors/selectors';
import Componentsloader from '../../common/componentsloader';

function PolicyGenerated() {
  const user = useSelector(userInfo)
  const userID = user && user.id
  const policyCards = useSelector(state => policyCardByUserID(state, userID))
  const isLoading = useSelector(state => state.pagenation.loading)


  return (
    <div className='mt-10 p-2 py-4 lg:py-2'>
    <p className='px-6 font-semibold text-2xl'>Policy Cards Generated</p>

    {isLoading ?
    <Componentsloader />
    :
    <>
    <div className='flex flex-wrap justify-center items-center text-xs h-[300px]
     overflow-y-scroll scroll-bar-in-dark'>
      {policyCards.length === 0
      ?
      <p className='font-bold text-2xl'>No Policy Card data</p>
      :
        <>
        {policyCards
             ? policyCards.map(({eid,category,content, policy_makers,
            regional_info, effective_date }, idx) => {
                return (
                    <Link
                    to={`/dashboard/${eid}`}
                    key={idx}
                    className='w-[300px] my-4'>
                    <PolicyCardContent
                cardInProfileUrl={true}
                currentMode={true}
                category={category}
                content={content}
                policy_makers={policy_makers}
                regional_info={regional_info}
                effective_date={effective_date}
                showAll={false}
                  />
                    </Link>
                )
          })
          :

          (<p className='font-bold text-2xl'>No Policy Card data</p>)
          }
        </>
      }
    </div>

  {policyCards.length > 2
  &&
  <p className='my-3 text-center font-semibold text-sm'>
    Scroll down to see more
    <IoMdArrowDropdown className='text-xl mx-auto text-white' />
    </p>
    }
    </>
    }




    </div>
  )
}

export default PolicyGenerated
