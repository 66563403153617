import React, { useEffect } from 'react'
import EachDebateActivity from './EachDebateActivity'
import { readableDate } from '../common/set_date'
import { useDispatch, useSelector } from 'react-redux'
import { getAllDIscussions } from '../../redux/Thunks/discussionsThunk'




function DashUserDebateTopics() {

  const dispatch = useDispatch()
    const allDiscussions = useSelector(state => state.discussions.allDiscussions)

    let height = "h-[100vh]"
    if(allDiscussions.length < 4){
        height = "h-[70vh] sm:h-[55vh]"
    }

  useEffect(() => {

    dispatch(getAllDIscussions())

  },[dispatch])

  return (
  <>
      {allDiscussions.length > 0 && (
        <div className={`${height} relative bg-nightModeDark_600 rounded-lg text-white overflow-y-scroll
     scroll-bar-in-dark pb-10`}>
    <p className='sticky top-0 p-4 bg-nightModeDark_600 w-full z-[2]
    text-xl sm:text-2xl font-bold mb-5'>Discussions</p>
        
        {allDiscussions?.map(({discussion_id,topic, author, author_image, comment_with_the_most_impression,
                            description, created_at, comment_date, comment_with_the_most_impression_owner, 
                            comment_with_the_most_impression_owner_image,upvote_count, downvote_count}) => {
            
            const dateObject = readableDate(created_at)

            return (<div className='px-4 sm:px-5 py-1 border-b-[0.5px] border-gray-500' key={discussion_id}>
                <EachDebateActivity
                discussion_id={discussion_id}
                topic={topic} 
                author={author}
                author_image={author_image}
                comment_with_the_most_impression_owner={comment_with_the_most_impression_owner} 
                commentOwnerProfileImage={comment_with_the_most_impression_owner_image}
                date={dateObject}
                text={comment_with_the_most_impression}
                upvote_count={upvote_count}
                downvote_count={downvote_count}
         />
            </div>)
        })}

        
        
      
    </div>
      )}
    </>
  )
}

export default DashUserDebateTopics
