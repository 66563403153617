import {
    createAsyncThunk
} from "@reduxjs/toolkit";
import api from "../../store/api/api"
import {
    headerConfiguration,
    headers
} from "../../store/auth/headerConfig";
import {
    toast
} from "react-toastify";



export const createDebate = createAsyncThunk(
    "debates/createDebate",
    async (payload, {
        dispatch,
        getState
    }) => {


        try {

            const url = `${process.env.REACT_APP_SERVER_URL}/api/debates/`

            // Create FormData object
            const formData = new FormData();
            formData.append('topic', payload.topic);
            formData.append('description', payload.description);
            formData.append('author', payload.author);
            formData.append('start_at', payload.start_at);
            formData.append('duration', payload.duration);
            formData.append('supporting', JSON.stringify(payload.supporting));
            formData.append('opposing', JSON.stringify(payload.opposing));

            // Append each media file
            if (payload.media && payload.media.length > 0) {
                payload.media.forEach((file) => {
                    formData.append('media', file);
                });
            }
            


            const headerConfig = headerConfiguration(getState, headers, true);
            delete headerConfig['Content-Type']; // Important: remove Content-Type header

            toast.loading("Creating debate...")
            const response = await api(url, "POST", headerConfig, formData, dispatch);
            toast.dismiss()
            toast.success(response?.message)
            return response;

        } catch (error) {
            console.error(error);
            toast.dismiss()
            toast.error(error.message || 'Failed to create debate');
            return error;
        }

    }
)


export const getAllDebates = createAsyncThunk(
    "debates/getAllDebates",
    async (_,{dispatch, getState}) => {
        try {
            
            const url = `${process.env.REACT_APP_SERVER_URL}/api/debates/`
            const headerConfig = headerConfiguration(getState, headers)
            const response = await api(url, "GET", headerConfig, null, dispatch)
            return response

        } catch (error) {
            console.log(error)
            return error
        }
    }
)


export const getEachDebate = createAsyncThunk(
    "debates/getEachDebate",
    async (payload,{dispatch, getState}) => {

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/api/debates/${payload.debate_id}/`
            const headerConfig = headerConfiguration(getState, headers)
            const response = await api(url, "GET", headerConfig, null, dispatch)
            return response

        } catch (error) {
            console.log(error)
            return error
        }
    }
)


export const createDebateComment = createAsyncThunk(
    "debates/createDebateComment",
    async ({debate_id, author, text, parent},{dispatch, getState}) => {

        const body ={
            debate : debate_id,
            author,
            text,
            parent
        }

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/api/debates/comments/${debate_id}/`
            const headerConfig = headerConfiguration(getState, headers)
            const response = await api(url, "POST", headerConfig, body, dispatch)
            toast.dismiss()
            toast.success(response?.message)
            return response;

        } catch (error) {
            console.error(error);
            toast.dismiss()
            toast.error(error?.message || 'Failed to create comment');
            return error;
        }
    }
)


export const getEachDebateComments = createAsyncThunk(
    "debates/getEachDebateComments",
    async (payload,{dispatch, getState}) => {

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/api/debates/comments/${payload.debate_id}/`
            const headerConfig = headerConfiguration(getState, headers)
            const response = await api(url, "GET", headerConfig, null, dispatch)
            return response

        } catch (error) {
            console.log(error)
            return error
        }
    }
)

export const editDebateComment = createAsyncThunk(
    "debates/editDebateComment",
    async (payload,{dispatch, getState}) => {

        const body ={
            text : payload.edited_comment
        }

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/api/debates/comments/${payload?.eid}/`
            const headerConfig = headerConfiguration(getState, headers)
            const response = await api(url, "PATCH", headerConfig, body, dispatch)
            return response;

        } catch (error) {
            console.error(error);
            toast.dismiss()
            toast.error(error?.message || 'Failed to edit comment');
            return error;
        }
    }
)


export const deleteDebateComments = createAsyncThunk(
    "debates/deleteDebateComments",
    async (payload,{dispatch, getState}) => {

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/api/debates/comments/${payload?.eid}/`
            const headerConfig = headerConfiguration(getState, headers)
            const response = await api(url, "DELETE", headerConfig, null, dispatch)
            return response

        } catch (error) {
            console.log(error)
            return error
        }
    }
)