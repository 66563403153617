import React,{useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { sortCommentFunction, userInfo } from '../../redux/Selectors/selectors';
import { sortingCategory } from '../../redux/Slices/sharedUseEffectSlice';
import { arrayInputOnChange, changeArrayItemState, resetActions } from '../common/show_and_hide';
import { createDiscussionComment, deleteDiscussionComments, editDiscussionComment, getEachDiscussionComments } from '../../redux/Thunks/discussionsThunk';
import { readableDate } from '../common/set_date';
import CommentImpression from '../Dashboard/DashCRUD/commentImpression';
import { Replies, ShowReplies } from '../Dashboard/DashCRUD/Replies';
import ChatPrompt from '../ChatBox/ChatPrompt';
import ChatPopUp from '../ChatBox/ChatPopUp';


// import ChatPopUp from '../../ChatBox/ChatPopUp'
// import ChatPrompt from '../../ChatBox/ChatPrompt'
// import { sortCommentFunction, userInfo } from '../../../redux/Selectors/selectors'

// import { createDebateComment, deleteDebateComments, editDebateComment, getEachDebateComments } from '../../../redux/Thunks/debatesThunk'
// import CommentImpression from '../../Dashboard/DashCRUD/commentImpression'
// import { arrayInputOnChange, changeArrayItemState, resetActions } from '../../common/show_and_hide'
// import { sortingCategory } from '../../../redux/Slices/sharedUseEffectSlice'
// import { Replies, ShowReplies } from '../../Dashboard/DashCRUD/Replies'
// import { readableDate } from '../../common/set_date'


const DiscussionComments = () => {
  let userID;
  const user = useSelector(state => userInfo(state))
  if (user) {
      userID = user.id;
  }

  

  const { discussion_id } = useParams(); // Access the parameter value
  const dispatch = useDispatch()

  // const reactionsPerComment = useSelector(reactionsbyID)


  const [popUp, setPopUp] = useState(false)  //PopUp Hidden initially

  const [unFilteredComments, setUnFilteredComments] = useState([])
  const [comments_with_null_parent, setComments_with_null_parent] = useState([])
  const sortedBy = useSelector(sortingCategory)
  const sortedComments = sortCommentFunction(sortedBy, comments_with_null_parent)


  const [editAction, setEditAction] = useState(Array.from({ length: comments_with_null_parent.length }, () => false));
  const [showHideReplies, setShowHideReplies] = useState(Array.from({ length: comments_with_null_parent.length }, () => false));
  const [hideBox, setHideBox] = useState(Array.from({ length: comments_with_null_parent.length }, () => false));
  const [moreAction, setMoreAction] = useState(Array.from({ length: comments_with_null_parent.length }, () => false));

  const [comment, setComment] = useState("")
  const [reply, setReply] = useState("")
  const [commentID, setCommentID] = useState("")
  let [inputValue, setInputValue] = useState(
      Array.from({ length: comments_with_null_parent.length }, () => "")
  );


  // SHOW AND HIDE REPLIES
  const handleClick = (index) => {
      changeArrayItemState(index, showHideReplies, setShowHideReplies)
  }

  // SHOW AND HIDE REPLY INPUT
  const toggle_reply_box = (index) => {
      resetActions(index, comments_with_null_parent, setHideBox)
      setMoreAction(Array.from(
          { length: comments_with_null_parent.length },
          () => false
      ))
  }

  // SHOW AND HIDE EDIT/DELETE CONTAINER
  const show_more_actions = (index) => {
      changeArrayItemState(index, moreAction, setMoreAction)
  }

  //ON COMMENT CHANGE
  const inputOnChange = (index, e) => {
      arrayInputOnChange(index, e, inputValue, setInputValue)
  }

  // POST COMMENT
const submitComment = async (e) => {
    e.preventDefault();
    toggle_reply_box()
    if (comment) {
        try {
            await dispatch(
                createDiscussionComment({
                    discussion_id,
                    author: userID,
                    text: comment,
                    parent: null
                })
            ).unwrap(); 

            setComment(""); 
            getCommentData(discussion_id)
            
        } catch (error) {
            console.error("Error posting comment:", error);
        }
    }
}

  // POST REPLY
  const submitReply = async (id) => {
      toggle_reply_box()
      if (reply) {
        try {
            await dispatch(
                createDiscussionComment({
                  discussion_id,
                  author: userID,
                  text: reply,
                  parent: id
              })
              ).unwrap()

              setReply("")
              getCommentData(discussion_id)


        } catch (error) {
            console.error("Error posting reply:", error);
        }
      }


  }

  // SHOW THE EDIT INPUT BOX
  const handleEdit = (comment_id, index) => {
      setInputValue(
          comments_with_null_parent.map(({ text }) => {
              return text;
          })
      )
      resetActions(index, comments_with_null_parent, setEditAction)
      setCommentID(comment_id)
      show_more_actions(index)
  }

  // UPDATE COMMENT
  const confirmUpdate = async (comment_id, index) => {
    handleEdit();
    show_more_actions();
    toggle_reply_box();

    if (!inputValue[index]) {
        alert("This field cannot be empty!");
        return;
    }

    try {
        await dispatch(
            editDiscussionComment({
                eid: comment_id,
                edited_comment: inputValue[index]
            })
        ).unwrap();

        // Fetch updated comments after successful edit
        getCommentData(discussion_id)
    } catch (error) {
        console.error("Error updating comment:", error);
    }
}

// DELETE COMMENT
  const handleDelete = async () => {
    toggle_reply_box()
    if (commentID) {
        try {
            await dispatch(
                deleteDiscussionComments({
                    eid: commentID
                })
            ).unwrap(); 
            // Fetch updated comments after successful deletion
            getCommentData(discussion_id)
            setPopUp(!popUp)

        } catch (error) {
            console.error("Error deleting comment:", error);
        }
    }
}

  const reactionActivity = (e, user_id, comment_id) => {

      if (e.target.id === "Thumbs_Up") {
          dispatch(
              // reaction_activity({
              //     eid: comment_id,
              //     user_id,
              //     content_type: "comment",
              //     vote_type: 'U'
              // })
          )
      } else if (e.target.id === "Thumbs_Down") {
          dispatch(
              // reaction_activity({
              //     eid: comment_id,
              //     user_id,
              //     content_type: "comment",
              //     vote_type: 'D'
              // })
          )
      }

      // GET REACTION ON THIS COMMENT
      dispatch(
          // get_reaction_activity_by_user({
          //     user_id,
          //     eid: comment_id
          // })
      )


  }

function getCommentData(id){
    dispatch(getEachDiscussionComments({ discussion_id : id }))
    .then(({ payload }) => {
        if (Array.isArray(payload)) {
            setUnFilteredComments(payload);
            const mainComment = payload?.filter(({ parent }) => parent === null);
            setComments_with_null_parent(mainComment);
        }
    });
}

useEffect(() => {

    getCommentData(discussion_id)

}, [discussion_id, dispatch, sortedBy]); 


  return (
      <div>

              <div>
                  {sortedComments &&

                    sortedComments.map(({ eid, debate, date_created, upvote_count,
                          downvote_count, text, author, deleted, repliesTotal }, index) => {



                          const date_object = readableDate(date_created)
                          // Filter all comments to only show those that the parent field is not null.
                          // These will be the reply to each comments
                          const replies_per_comment = unFilteredComments && unFilteredComments.filter(({ parent }) => {
                              return String(parent) === String(eid)
                          })


                          return (
                              <div key={index} data-id={eid}>
                                  <CommentImpression
                                      id={eid}
                                      deleted={deleted}
                                      commentPoster={author?.id}
                                      author_ID={userID}
                                      index={index}
                                      username={author?.username}
                                      img={author?.user_image}
                                      comment_date={date_object}
                                      comment_text={text}
                                      upvote_count={upvote_count}
                                      downvote_count={downvote_count}
                                      // userHasReacted={matched}
                                      replyComment={submitReply}
                                      reply={reply}
                                      handleReplyChange={e => setReply(e.target.value)}
                                      replyPromptShow={hideBox}
                                      edit_delete_div={moreAction}
                                      edit_box={editAction}
                                      input_value={inputValue[index]}
                                      set_Input_Value={(e) => inputOnChange(index, e)}
                                      toggleReplyBox={() => {
                                          toggle_reply_box(index)
                                          changeArrayItemState(index, hideBox, setHideBox)
                                      }}
                                      showMoreActions={show_more_actions}
                                      cancelAction={() => {
                                          handleEdit()
                                          show_more_actions()
                                          toggle_reply_box()
                                      }}
                                      deleteComment={(comment_id) => {
                                          setCommentID(comment_id)
                                          setPopUp(!popUp)
                                      }}
                                      handleUpdate={confirmUpdate}
                                      editComment={handleEdit}
                                      setInputValue={e => setCommentID(e.target.value)}
                                      // reaction={(e) => {
                                      //     reactionActivity(e, userID, eid)
                                      // }}
                                  />

                                  <ShowReplies
                                      index={index}
                                      repliesTotal={repliesTotal}
                                      seeReplies={handleClick}
                                      setArray={showHideReplies}
                                  />

                                  <Replies
                                      showHideReplies={showHideReplies}
                                      index={index}
                                      author_ID={userID}
                                      showMoreActions={show_more_actions}
                                      edit_delete_div={moreAction}
                                      arrayToMap={replies_per_comment}
                                      deleteComment={(comment_id) => {
                                          setCommentID(comment_id)
                                          setPopUp(!popUp)
                                      }}
                                      // userHasReacted={matched}
                                      reaction={(e, innerCommentID) => {
                                          reactionActivity(e, userID, innerCommentID)
                                      }}

                                  />

                                  <hr />
                              </div>
                          )
                      })
                  }
              </div>

         {/* Close of comments section below */}
        
          <ChatPrompt
              currentMode={true}
              bgOnDark="bg-nightModeDark"
              position="static"
              placeholder="Post your comment here..."
              submitPrompt={submitComment}
              value={comment}
              handleChange={(e) => {
                  setComment(e.target.value)
              }} />


          {/* POP UP */}
          {popUp && <ChatPopUp
              objectModel="Comment?"
              handleClosePopUp={() => setPopUp(!popUp)}
              handleCancel={() => setPopUp(!popUp)}
              promptTarget="This comment"
              handleDelete={handleDelete}

          />}




      </div>

  );
}

export default DiscussionComments