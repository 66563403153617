import React from 'react'
import Footer from '../../layout/Footer'
import Summary from './summaries/summary'
import DiscussionsBoard from './DiscussionsBoard'
import DiscussionCommentSection from './DiscussionCommentSection'

const Discussions = () => {
  return (
    <div className='bg-nightModeDark text-white min-h-max'>
    <div className='flex flex-col lg:flex-row gap-4 m-1 md:m-4'>
    <DiscussionsBoard/>
    <Summary/>
    </div>

    <DiscussionCommentSection />

      <Footer/>
    </div>
  )
}

export default Discussions