import React from 'react'

const DebateChat = ({name,containerClass,stance, time}) => {
  return (
    <div className={`${containerClass}`}>
    <div className='flex  flex-col md:flex-row md:justify-between  md:gap-2  md:items-center'>
      <h3 className='font-semibold  my-2 text-sm sm:text-base'>{name?.slice(0,16)}</h3>
      <div className='my-1 md:my-2 font-light'>
        <p className='text-sm sm:text-base'>{time}</p>
      </div> 

    </div>

      <div className='my-2'>
        <p className='font-nunito font-medium text-sm sm:text-xl'>{stance}</p>
      </div> 
    </div>
  )
}

export default DebateChat