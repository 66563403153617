import React, { useState } from 'react'
import UserProfile from '../components/Profile/UserProfile'
import Footer from '../layout/Footer'
import MarketPlaceQuery from '../components/MarketPlace/MarketPlaceQuery'
import Purchased from '../components/MarketPlace/Purchased'
import { ArrowLeft  } from 'lucide-react';
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'


function MarketPlaceDataSearch() {
    const navigate = useNavigate()
    const renderedComp = useSelector(state => state.shared_useEffect.datasetQueryComponent)
    const [component, setComponent] = useState(renderedComp)

    const toggleComponent = (e) => {
        setComponent(e.target.id)
    }



    return (
        <div>
            <div className='bg-greenOnDarkMode text-white py-5 px-2.5 sm:p-5 mt-[100px]'>
                <UserProfile />

                <div className='relative sm:bg-nightModeDark px-2 sm:p-4 rounded-xl'>
                <button 
                onClick={() => {
                    navigate(-1)
                }}
                className='hidden md:block absolute top-0 p-2.5 text-[16px] hover:opacity-75'>
                <ArrowLeft  className='mr-0.5 inline'/> Go Back
                </button>

                    <p className='text-center font-bold text-2xl my-3 mt-5'>Welcome to the Data Marketplace! </p>

                    <div className='flex flex-col justify-center lg:pl-[15%] lg:pr-[15%] mt-8'>
                    <div className='flex w-full'>
                    <button
                    type='submit'
                    className={`rounded-tl-md py-2 px-6 text-white w-full sm:w-auto
                    ${component === "query" 
                    ? "bg-[#211919] sm:bg-[#5B5B5B]"
                     : "bg-[#211919]" }
                    `}
                    id="query"
                    onClick={toggleComponent}>Search</button>

                    <button
                    type='submit'
                    className={`rounded-tr-md py-2 px-6 text-white w-full sm:w-auto
                     ${component === "manage"
                      ? "bg-updatedPurple" 
                      : "bg-greenOnDarkMode  sm:bg-[#211919]" 
                      }
                    `}
                    id='manage'
                    onClick={toggleComponent}>Manage</button>
                    </div>


                    <div className={` w-full mb-5 md:px-6 py-3 rounded-b-2xl sm:rounded-tr-2xl
                     ${component === "manage" 
                     ? "bg-updatedPurple" 
                     : "bg-[#211919] sm:bg-[#5B5B5B]" }
                    `}>
                    {component === "query"
                    ?
                    <MarketPlaceQuery />
                    :
                    <Purchased />
                    }
                    </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MarketPlaceDataSearch
