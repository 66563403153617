import React, { useEffect, useState } from 'react'
import AiSummary from './AiSummary'
import VideoFiles from './VideoFiles'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


const Summary = () => {
  const { discussion_id } = useParams()
  const discussion = useSelector(state => state.discussions.oneDebate)
  const debate = useSelector(state => state.debates.oneDebate)
  const [activeTab, setActiveTab] = useState(1);

  const handleClickTab = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const getButtonStyles = (tabIndex) => {
    return activeTab === tabIndex
      ? 'bg-black text-white' // Active button styles ( black background and white text)
      : 'bg-[#202020] text-white'; // Inactive button styles (gray background and white text)
  };


  useEffect(() => {

  },[discussion_id])

  
  return (
    <div className='max-h-screen relative overflow-y-scroll scroll-bar-in-dark 
    w-full lg:w-1/3 rounded-lg bg-black'>
      <div className='sticky top-0 w-full z-[2] flex justify-between items-center'>
        <button
          className={`${getButtonStyles(1)} px-4 py-2 w-2/4`} 
          onClick={() => handleClickTab(1)}
        >
          <span className='font-nunito text-[10px] md:text-sm md:font-bold'>Media Files</span>
        </button>
        <button
          className={`${getButtonStyles(2)} px-4 py-2 w-2/4`} 
          onClick={() => handleClickTab(2)}
        >
          <span className='font-nunito text-[10px] md:text-sm md:font-bold'>Ai Summary</span>
        </button>
      </div>

      <div className='p-5 mt-2.5 bg-black'>
        {activeTab === 1 && <div><VideoFiles media={discussion_id ? discussion?.media : debate?.media} /></div>}
        {activeTab === 2 && <div><AiSummary/></div>}
      </div>
    </div>
  );
};

export default Summary;

