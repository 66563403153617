import React from "react";


const Products = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Product
          </h2>
          {/* Add your sign-in form here */}
        </div>
      </div>
    </div>
  );
};

export default Products;
