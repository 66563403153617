import React from 'react'

function Drafts() {
  return (
    <div className='bg-[#101010] relative pb-5 rounded-md'>

    <p className='w-full bg-[#101010] sticky top-0 p-4 text-xl font-bold'>Drafts</p>

    <div className='px-4 py-8 h-[100vh] overflow-y-scroll no-scrollbar'>
        {[0,1,2,3].map((_,index) => {
            return (
            <div key={index} className='flex justify-center items-center h-[200px] bg-[#7E6E6E] my-5 rounded-xl'>
                <h4 className='font-bold text-xl'>Title</h4>
            </div>
            )
        })}

    </div>
    </div>
  )
}

export default Drafts