import React from 'react'
import DebateTeam from './DebateTeam'



const DebateRoom = ({debateDetails}) => {


  return (
     <>
   {debateDetails?.debate_id &&
   (

    <div className={`bg-[#202020] relative  text-white w-full lg:w-2/3  rounded-lg`}>
        <div className='flex flex-row justity-center items-center p-4'>
            <h1 className='font-bold text-white text-sm md:text-xl lg:text-3xl md:p-5'>
              Welcome to the Debate Room!
            </h1>
            {/* <Link to="/discussion">
            <div className='flex justify-center md:pr-5'>
            <button className=' bg-[#7AA65F] rounded-xl text-sm sm:text-[16px] py-1 px-2 md:py-2 md:px-5 text-center'>Jump to Discussions</button>
            </div>
            </Link> */}
        </div>
        {debateDetails &&
            <div className='ml-5'>
            
              <div className='bg-[#202020] flex flex-col sm:justify-center py-5 px-2.5 sm:px-4'>

        <div className='flex justify-around flex-col sm:px-4 mt-5'>
        <h3 className='text-2xl font-bold'>{debateDetails?.topic}</h3>
          <p className='italic font-light my-1'><i>Created by:</i> {debateDetails?.author?.username}</p>
          <p className='mt-4'>Remaining time: {debateDetails?.duration}</p>
        </div>


                    <div className='flex justify-between sm:justify-center my-5'>
              <DebateTeam debaters={debateDetails?.supporting} stance="Supporting" />
              <div className='flex justify-center items-center mx-3 md:mx-8  md:h-[270px]' >
                      <h3 className='text-[18px] sm:text-2xl font-bold'>Vs</h3>
              </div> 
              <DebateTeam debaters={debateDetails?.opposing} stance="Opposing" />
                    </div>

            
              </div>
              </div>
        }

    </div>
   )}
     </>
  )
}

export default DebateRoom