import React from 'react'
import DebateChat from '../../DebateChat'
import { SlLike, SlDislike } from "react-icons/sl";
import TextArea from './TextArea';

const Suggest = () => {
  return (
    <section className='p-1 md:p-4'>
      <div>
        <h1 className='font-bold font-nunito text-base md:text-2xl'>Suggested Arguments</h1>
      </div>

      <div className='h-[50vh] overflow-y-scroll scroll-bar-in-dark'>
        <div className='flex flex-col p-3'>
          <DebateChat
            name='James McFly'
            stance='Hello you have requested a policy card for Kansas! what would you like to know?'
            time='05:03:27PM CST'
            containerClass='w-3/4'
          />

          <div className='flex flex-row gap-2 space-x-4'>
            <div className='flex flex-row space-x-2'>
              <p>237</p>
              <div className='text-green-200'>
                <SlLike />
              </div>
            </div>
            <div className='flex flex-row space-x-2'>
              <p>29</p>
              <div className='mt-1 text-red-400'>
                <SlDislike />
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col p-3'>
          <DebateChat
            name='Martin Kellogg'
            stance='I would like to know about the policy that just passed 2 days ago about gun laws.'
            time='05:07:31PM CST'
            containerClass='w-3/4'
          />

          <div className='flex flex-row gap-2 space-x-4'>
            <div className='flex flex-row space-x-2'>
              <p>237</p>
              <div className='text-green-200'>
                <SlLike />
              </div>
            </div>
            <div className='flex flex-row space-x-2'>
              <p>29</p>
              <div className='mt-1 text-red-400'>
                <SlDislike />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TextArea />

    </section>
  )
}

export default Suggest