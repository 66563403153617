import {
    createAsyncThunk
} from "@reduxjs/toolkit";
import { headers, headerConfiguration } from "../../store/auth/headerConfig";
import api from "../../store/api/api";



// CREATE POLICY  CARD
export const create_policy_card_API = createAsyncThunk(
    "policyCard/create_policy_card_API",
    async (payload, {getState, dispatch}) => {

        const headerConfig = headerConfiguration(getState, headers)
        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/`
            const body = {
                content: payload.content,
                category: payload.category,
                policy_makers: payload.policy_makers,
                regional_info: payload.regional_info,
                location: payload.location,
                userneed_id: payload.userneed_id,
                effective_date: payload.effective_date,
                voting_status: payload.voting_status,
                policy_author_id: payload.policy_author_id
            }
            const response = await api(url, "POST", headerConfig, body, dispatch);
            return response
        } catch (error) {
         // console.error(error)
         return (error?.message);
        }
    })

// GET ALL POLICY CARDS FROM THE SERVER
export const get_all_policy_card_API = createAsyncThunk(
    "policyCard/get_all__policy_card_API",
    async (payload, {getState, dispatch}) => {

        const headerConfig = headerConfiguration(getState, headers)
        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/`;

            const response = await api(url, "GET", headerConfig, null, dispatch);
            const loc = payload?.clickedLoc

            if(loc){
                const filteredByClickedLocation = response && response.filter(item => {
                    return item?.location.toLowerCase().includes(loc.toLowerCase())
                })

                return filteredByClickedLocation
            }else{
                return response
            }

        } catch (error) {
         // console.error(error)
         return (error?.message);
        }
    }
);


// GET ALL POLICY CARDS BY LOCATION
export const get_all_policy_card_by_location_API = createAsyncThunk(
    "policyCard/get_all_policy_card_by_location_API",
    async (payload, {getState, dispatch}) => {
        const headerConfig = headerConfiguration(getState, headers)
        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/location/${payload.location}/`;
            const response = await api(url, "GET", headerConfig, null, dispatch);
            return response
        } catch (error) {
         // console.error(error)
         return (error?.message);
        }
    }
);


// DELETE POLICY CARD BY ID FROM SERVER
export const delete_policy_card_API = createAsyncThunk(
    "policyCard/delete_policy_card_API",
    async (payload, {getState, dispatch}) => {

        const headerConfig = headerConfiguration(getState, headers)
        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/${payload.eid}/`

            const response = await api(url, "DELETE", headerConfig, null, dispatch);
            if(!response){
                return payload.eid; // Return the parsed response data
            } else {
                // Handle error cases here, you can throw an error or return an error object
                const errorData = await response.json();
                throw new Error(errorData.message); // For example, assuming the API returns an error message in the response
            }

        } catch (error) {
        // console.error(error)
        return (error?.message);
        }
    }
)


// GENERATE POLICY CARD BY SURVEY
export const surveyCards = createAsyncThunk(
    "policyCard/surveyCards",
    async ({survey_id, transcript_id}, {getState, dispatch}) => {

        const headerConfig = headerConfiguration(getState, headers)

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/api/policycardgeneration/${survey_id}/${transcript_id}`
            const response = await api(url, "GET", headerConfig, null, dispatch);
            if(response?.message){
                return null; // Return the parsed response data
            } else {
                // Handle error cases here, you can throw an error or return an error object
                const errorData = await response.json();
                throw new Error(errorData.message); // For example, assuming the API returns an error message in the response
            }

        } catch (error) {
         // console.error(error)
         return (error?.message);
        }
    }
);
