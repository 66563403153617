import { createSlice } from "@reduxjs/toolkit";
import { createDiscussion, createDiscussionComment, 
    deleteDiscussionComments, 
    editDiscussionComment, 
    getAllDIscussions, getEachDiscussion, 
    getEachDiscussionComments} from "../Thunks/discussionsThunk";
import { setError, setLoadingAndError } from "../../components/common/slice_state";


const initialState = {
    allDiscussions : [],
    oneDiscussion : null,
    discussionComments : [],
    drafted: [],
    loading: false,
    error: null
}


export const discussionSlice = createSlice({
    name : "discussions",
    initialState,
    reducers: {
        deleteDiscussions: (state, action) => {

        }
    },
    extraReducers: (builder) => {
        builder

        .addCase(createDiscussion.pending, setLoadingAndError)
        .addCase(createDiscussion.fulfilled, (state, action) => {
            state.error = null
            state.loading = false
        })
        .addCase(createDiscussion.rejected, setError)


        .addCase(getAllDIscussions.pending, setLoadingAndError)
        .addCase(getAllDIscussions.fulfilled, (state, action) => {
            state.error = null
            state.loading = false
            state.allDiscussions = action.payload || []
        })
        .addCase(getAllDIscussions.rejected, setError)

        
        .addCase(getEachDiscussion.pending, setLoadingAndError)
        .addCase(getEachDiscussion.fulfilled, (state, action) => {
            state.error = null
            state.loading = false
            state.oneDiscussion = action.payload || null
        })
        .addCase(getEachDiscussion.rejected, setError)


        

        .addCase(createDiscussionComment.pending, setLoadingAndError)
        .addCase(createDiscussionComment.fulfilled, (state) => {
             state.loading = false
             state.error = null
        })
        .addCase(createDiscussionComment.rejected, setError)



        .addCase(getEachDiscussionComments.pending, setLoadingAndError)
        .addCase(getEachDiscussionComments.fulfilled, (state, action) => {
             state.loading = false
             state.error = null
             state.discussionComments = action.payload || []
        })
        .addCase(getEachDiscussionComments.rejected, setError)



        .addCase(editDiscussionComment.pending, setLoadingAndError)
        .addCase(editDiscussionComment.fulfilled, (state) => {
             state.loading = false
             state.error = null
        })
        .addCase(editDiscussionComment.rejected, setError)



        .addCase(deleteDiscussionComments.pending, setLoadingAndError)
        .addCase(deleteDiscussionComments.fulfilled, (state) => {
             state.loading = false
             state.error = null
        })
        .addCase(deleteDiscussionComments.rejected, setError)

    }
})



export default discussionSlice.reducer