import React from 'react';
import { GoThumbsup, GoThumbsdown } from "react-icons/go"


const RepliesSection = ({size, username, comment_date, comment_text, img,
                            upvote_count, downvote_count, reactAction, user_Has_Reacted }) => {


    return (
        <div className='flex flex-wrap ml-[8%] sm:ml-[14%]'>

<div className='flex px-3'>
            <div className='w-[35px] md:pt-4'>
                <img 
                src={img} 
                alt={`${username} profile`}
                className='w-full h-[35px] rounded-full'
                />
            </div>


                <div className='px-3 lg:p-4 w-[90%] ml-3'>
                <p className='md:inline ml-0'>{username}</p>
                <p className='inline m-1 my-2.5 sm:m-2.5 text-xs md:text-sm'>{comment_date}</p>
                    {/* <p className='m-2.5'>Title</p> */}
                    <p className='my-3'>
                    {comment_text}
                    </p>

                    <div className='flex w-full my-3 space-x-6'>
                    <p>{upvote_count}
                        <GoThumbsup
                            onClick={(e) => {
                                reactAction(e)

                            }}
                            id="Thumbs_Up"
                            className={`
                            mx-1 text-2xl hover:text-4xl inline cursor-pointer
                             ${( (user_Has_Reacted && user_Has_Reacted[0]?.vote_type === "U") && upvote_count > 0)
                             && "text-[#3C6E71]"}
                             `}
                        />
                    </p>



                    <p>{downvote_count}

                        <GoThumbsdown
                            onClick={(e) => {
                                reactAction(e)
                            }}
                            id="Thumbs_Down"
                            className={`
                            mx-1 text-2xl hover:text-4xl inline cursor-pointer
                             ${( (user_Has_Reacted && user_Has_Reacted[0]?.vote_type === "D") && downvote_count > 0)
                             && "text-[#3C6E71]"}
                             `}
                        />
                    </p>
                    </div>
            </div>

            </div>

            </div>
    );
}

export default RepliesSection;
