import React from 'react'

function DebateDuration({duration, onFilterChange}) {
  return (
    <div className='w-full md:w-2/4'>
    <select
    className='w-full rounded-md py-2 text-left 
    text-black bg-white hover:bg-gray-100 font-bold'
      value={duration}
      name="duration"
      onChange={onFilterChange}
    >
      <option value="30">30 minutes</option>  
      <option value="45">45 minutes</option>
      <option value="60">60 minutes</option>
      <option value="90">90 minutes</option>
      <option value="120">120 minutes</option>
    </select>
    </div>
  )
}

export default DebateDuration