import {
  clearAuth,
  setAuth,
} from "./slice";
import {
  toast
} from "react-toastify";
import {
  SERVER_URL
} from "../../config/config";
import {
  signup
} from "./sign_function";


export const login =
  ({
    email,
    password,
    navigate
  }) =>
  async (dispatch) => {
    toast.loading("Processing...");
    try {
      const response = await fetch(`${SERVER_URL}/api/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });
      if (!response.ok) {
        toast.dismiss();
        toast.error("Invalid Credentials, Login Failed!");
      } else {
        const data = await response.json();
        if (data) {
          toast.dismiss();
          navigate("/");
          toast.success("Login Success.");
        }
        dispatch(setAuth({
          data
        }));
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Network Error!");
      navigate("/signin");
    }
  };

export const signout =
  ({
    navigate
  }) =>
  async (dispatch) => {
    dispatch(clearAuth());
    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")
    navigate("/");
  };



export const signupUser = ({
  email,
  password,
  firstName,
  lastName,
  phone_number,
  country,
  state,
  city,
  navigate,
}) => async (dispatch) => {

  const url = `${SERVER_URL}/api/register/`
  const body = {
    first_name: firstName,
    last_name: lastName,
    email: email,
    password: password,
    phone_number: phone_number,
    country: country,
    state: state,
    city: city,
  }

  // Dispatch the signup thunk action
  await dispatch(signup(navigate, url, body));
}


export const signupCompany = ({
  email,
  password,
  company_name,
  company_type,
  country,
  state,
  city,
  phone_number,
  navigate
}) => async (dispatch) => {
  const url = `${SERVER_URL}/api/company/register/`
  const body = {
    email,
    password,
    company_name,
    company_type,
    country,
    state,
    city,
    phone_number
  }
  // Dispatch the signup thunk action
  await dispatch(signup(navigate, url, body))
}
