import React from 'react'
import { Link } from 'react-router-dom'

const VideoFiles = ({media}) => {
  
  return (
    <div className='flex justify-center w-full flex-col'>
    

      {media && media.length > 0 ? media.map((item, idx) => {
        return( <div key={idx} className='flex m-2 my-4 h-[250px] w-[350px]'>
          {item.endsWith('mp4') ?  (
                      <video
                          src={item}
                          className="w-full h-full object-cover rounded-xl"
                          controls
                      />
                  )
                  :
                  (
                    <Link to={item} target="_blank">
                      <img
                          src={item}
                          alt={`Media`}
                          className="w-full h-full object-cover rounded-xl"
                      />
                      </Link>
                  )}
          </div>)
      })
      :
      <p className='text-center my-4'>No Media File.</p>
      }

    </div>
  )
}

export default VideoFiles