import React, { useState } from 'react'
import CreateForm from './CreateForm'
import Drafts from './Drafts'


const discussionTxt = `An ongoing forum where anyone from anywhere can contribute at anytime. An ongoing
   forum where anyone from anywhere can contribute at anytime.
    An ongoing forum where anyone from anywhere can contribute at anytime.`

const debateTxt = `A 1-on-1 debate between 2 users. We will match the 2 best users for your 
  question or topic. Users can discuss with each other and vote for the side that they think
   best fit for a solution to the prompt.` 


function CreateDebateORDiscussion({whatToDisplay}) {

  const [createCategory, setCreateCategory] = useState("discussion")

   
  return (
    <div className='text-white bg-nightModeDark_600 rounded-lg'>

    <div className='sm:flex justify-between'>
      <div className='sm:w-2/5 m-4 p-4'>
      <h3 className='md:text-2xl lg:text-3xl font-bold'>Welcome! To get started, please choose whether you 
      are creating a discussion or a debate:</h3>
      </div>

        <div 
        onClick={() => {
          setCreateCategory("discussion")
        }}
        className={`${createCategory === "discussion" && "bg-nightModeDark" }
         cursor-pointer rounded-xl px-4 py-8 sm:py-4 m-4 sm:mx-0 sm:w-[30%]`}>
        <p className='font-bold text-center text-2xl mb-5'>Discussion</p>
        <p className='italic sm:text-[12px] lg:text-[16px]'>{discussionTxt}</p>
        </div>

        <div 
        onClick={() => {
          setCreateCategory("debate")
        }}
        className={`${createCategory === "debate" && "bg-nightModeDark" }
         cursor-pointer rounded-xl px-4 py-8 m-4 sm:w-[30%]`}>
        <p className='font-bold text-center text-2xl mb-5'>Debate</p>
        <p className='italic sm:text-[12px] lg:text-[16px]'>{debateTxt}</p>
        </div>

      </div>

      <div className='sm:flex my-10'>
      <div className='sm:w-[70%]'>
      <CreateForm category={createCategory} />
      </div>
      <div className='sm:w-[30%]'>
        <Drafts />
        </div>
      </div>
    </div>
  )
}

export default CreateDebateORDiscussion
