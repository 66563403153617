import React from 'react'
import { FaTimes } from 'react-icons/fa'

function UploadedImagesDisplay({media, removeMedia}) {
  return (
    <div className='flex flex-wrap'>
            {media && media.map((file, index) => (
        <div key={index} className="relative w-[120px] m-3 rounded-xl h-[120px]
            bg-gray-100">
            <FaTimes
                onClick={() => {
                    removeMedia(index)
                }}
                className='text-white rounded-full absolute cursor-pointer
                -top-2 -right-2 bg-black text-2xl'/>
            {file.type.startsWith('image/') ? (
                <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index + 1}`}
                    className="w-full h-full object-cover rounded-xl"
                />
            ) : (
                <video
                    src={URL.createObjectURL(file)}
                    className="w-full h-full object-cover rounded-xl"
                    controls
                />
            )}
        </div>)
    )}
    </div>
  )
}

export default UploadedImagesDisplay