import React from 'react'
import { FaTimes } from 'react-icons/fa'

function FinalDebaters({teamA, teamB, removeDebater}) {
  return (
    <div className='flex justify-between'>
    {teamA.length > 0 && 
    (
        <div>
        <p className='font-bold'>Supporting side</p>
        <div className='sm:flex'>
        {teamA.map((item, index) => (
            <div key={index} className="relative w-[70px] my-7 sm:mx-2 rounded-full h-[70px]
             bg-gray-100">
                <FaTimes
                    onClick={() => {
                        removeDebater(item?.id, "supp")
                    }}
                    className='text-white rounded-full absolute cursor-pointer
                    -top-2 -right-2 text-2xl'/>
                    <img
                        src={item.profileImage}
                        alt={`Preview ${index + 1}`}
                        className="w-full h-full object-cover rounded-full"
                    />
                   <p className='text-xs text-center mt-1'>{item?.name?.slice(0,7)}...</p>
            </div>)
        )}
        </div>
    </div>
    )}

    {teamB.length > 0 &&
    (
        <div>
        <p className='font-bold'>Opposing side</p>
        <div className='sm:flex'>
        {teamB.map((item, index) => (
            <div key={index} className="relative w-[70px] my-7 sm:mx-2 rounded-full h-[70px]
             bg-gray-100">
                <FaTimes
                    onClick={() => {
                        removeDebater(item?.id, "opp")
                    }}
                    className='text-white rounded-full absolute cursor-pointer
                    -top-2 -right-2 text-2xl'/>
                    <img
                        src={item.profileImage}
                        alt={`Preview ${index + 1}`}
                        className="w-full h-full object-cover rounded-full"
                    />
                    <p className='text-xs text-center mt-1'>{item?.name?.slice(0,7)}...</p>
            </div>)
        )}
        </div>
    </div>
    )}
</div>
  )
}

export default FinalDebaters