import CytoscapeComponent from 'react-cytoscapejs';
import { layout, stylesheet } from './psych_styles';
import { useEffect, useRef } from 'react';
import { X } from 'lucide-react';
import cytoscape from 'cytoscape';
import avsdf from 'cytoscape-avsdf';


cytoscape.use( avsdf );


const PsychZoomed = ({data, bgColor, handleClosePopUp}) => {
  const cyRef = useRef(null);

  const setConnectedClass = (cy) => {
    if (cy) {
      cy.edges().connectedNodes().addClass('connected_nodes');
    }
  };

  useEffect(() => {
    if (cyRef.current) {
      const cy = cyRef.current;
      cy.on('layoutstop', () => setConnectedClass(cy));
      setConnectedClass(cy);

        // Add rotation animation

    
    }
  }, []);

  return (
    <div className='h-full w-full flex justify-center items-center bg-white
                text-black p-6 fixed top-0 left-0 z-[1000] bg-opacity-75'>
          <style>
        {`
          @keyframes rotate {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          }
        `}
      </style>


      <X onClick={handleClosePopUp}
       className='absolute right-0 sm:right-3 top-0 sm:top-3 cursor-pointer text-3xl z-[1]'
       size={34}
        />

        {data && 
        <CytoscapeComponent
        elements={CytoscapeComponent.normalizeElements(data)}
        stylesheet={stylesheet}
        layout={layout}
        style={{ 
                 width: '85vw',
                 height: '90vh', 
                 backgroundColor: bgColor ? bgColor : "#000000", 
               }}
        cy={(cy) => { 
            cyRef.current = cy
             }}
        minZoom={0.0}
      />}

    </div>
  );
};

export default PsychZoomed;