export const isTranscriptDeleted =  (transcripts) => {
    if(transcripts && transcripts?.detail === "No Transcript matches the given query."){
        return []
    }else{
        return transcripts?.filter(Boolean)
    }
    
}

export const showErrorMessages = (message, setStateBool, setStateStr) => {
    setStateBool(true)
    setStateStr(message)
}

export const itemExists = (arrayData, item ) => {
    const existsInSupport = arrayData && arrayData.find(({id}) => {
        return id === item?.id
    })

    return existsInSupport
}
