import React, { useState } from 'react'
// import { PasswordResetInput } from '../common/input'
// import { interestOptions } from '../../mockups/User/interests'
import { useNavigate } from 'react-router-dom'

function Partnership({feedbackToDisplay}) {

    const navigate = useNavigate()

    const [text, setText] = useState("")

    const onInterestChange = (e) => {
        setText(e.target.value)
    }

    const submitInterests = () => {
        console.log(text)
    }



    return (
        <div>
            <div className="min-h-screen flex items-center justify-center
            bg-backgroundColor py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl w-full bg-white p-5 rounded-md">
                    <div className="bg-[#353535] rounded-md p-5 py-12">

                        <h2 className="mt-6 text-center text-4xl font-extrabold text-white">
                        Before you proceed
                        </h2>
                        <p className="text-white text-center mt-3 mb-5">
                        We would love to partner with you! <br />
                        Leave us a message and we will contact you shortly!
                            </p>

                        <div className='flex flex-col justify-center items-center py-4 '>
                            <div className='w-3/4 flex justify-center px-2'>
                            <textarea
                            className='mb-4 w-full rounded-lg h-[200px] bg-[#F3EDED]'
                            onChange={onInterestChange}
                            value={text}
                            >

                            </textarea>
                            </div>
                        </div>


                        <div className='flex justify-end mt-10'>
                            <button
                                className='block m-3 rounded-md py-2 px-6
                                text-white bg-updatedPurple hover:bg-[#767cb9]'
                                onClick={() => {
                                    navigate("/")
                                }}>Back</button>
                            <button
                                className='block m-3 rounded-md py-2 px-6
                                text-white bg-updatedPurple hover:bg-[#767cb9]'
                                onClick={submitInterests}>Submit</button>
                        </div>
                    </div>


                </div>


            </div>


        </div>
    )
}

export default Partnership
