import React, { useEffect, useRef, useState } from 'react'
import { transformData } from '../../common/transformNeo4j_to_Cytoscape';
import { testData } from '../../MarketPlace/Psychometric/dummyData';
import UserPsychometric from '../../MarketPlace/Psychometric/UserPsychometric';
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { generalUserNeedPsychometrics } from '../../../redux/Thunks/dashboardThunk';
import { all_userneed_psychometrics } from '../../../redux/Slices/dashboardSlice';
import PsychZoomed from '../../Psych/PsychZoomed';



function StatsPsychometrics() {
    const dispatch = useDispatch()
    const scrollContainerRef = useRef(null);

    const userneed_dashboard = useSelector(all_userneed_psychometrics)
    const [psychometric, setPsychometric] = useState([])

    const [psychBg, setPsychBg] = useState(null)
    const [displayFullPsychometric, setDisplayFullPsychometric] = useState(null)


    const getAnalysis = () => {
      const result = transformData(testData)
      setPsychometric(result)
    }

    const fetchSomeData = () => {
        dispatch(generalUserNeedPsychometrics())
    }

    const handlePsychometrics = (data, color) => {
      setPsychBg(color)
      setDisplayFullPsychometric(data)
    }

    const scroll = (direction) => {
        const container = scrollContainerRef.current;
        if (container) {
          const scrollAmount = container.clientWidth / 2; // Scroll by half the container's width
          // If direction is right, scroll by half of the div width
          // else If direction is left, scroll by negative half of the div width
          container.scrollBy({
            left: direction === 'right' ? scrollAmount : -scrollAmount,
            behavior: 'smooth'
          });
        }
      };


    useEffect(() => {  
        getAnalysis()
        fetchSomeData()

    // eslint-disable-next-line 
      },[testData])



 

  return (
    <div>
    <div className='flex relative h-[350px] my-4'>
    

    {/* ARROW LEFT */}
    <div className='h-full flex items-center'>
    <IoMdArrowDropleft
     onClick={() => scroll('left')}
     className='cursor-pointer
                 text-3xl md:text-6xl text-white' /> 
    </div>


{/* GRAPHS */}
       <div 
       ref={scrollContainerRef}
       style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
       className='flex w-[97%] overflow-y-hidden 
       overflow-x-scroll space-x-6'>

      <div onTouchStart={() => handlePsychometrics(psychometric, "#D20062")} onClick={() => handlePsychometrics(psychometric, "#D20062")}>
        <UserPsychometric
            data={psychometric}
            bgColor="#284B63"
        />
        </div>


        {userneed_dashboard?.nodes && 
         <div onTouchStart={() => handlePsychometrics(userneed_dashboard, null)} onClick={() => handlePsychometrics(userneed_dashboard, null)}>
        <UserPsychometric
            data={userneed_dashboard}
            bgColor="#000"
        />
        </div>}


        <div  onTouchStart={() => handlePsychometrics(psychometric, "#0D1282")} onClick={() => handlePsychometrics(psychometric, "#0D1282")}>
        <UserPsychometric
            data={psychometric}
            bgColor="#fff8"
        />
        </div>
       

       </div>

{/* ARROW RIGHT */}
       <div className='h-full flex items-center'>
     <IoMdArrowDropright 
     onClick={() => scroll('right')}
     className='cursor-pointer
                 text-3xl md:text-6xl text-white' />  
        </div>

     </div>



    {/* Display Full Psychometrics */}
     {displayFullPsychometric && 
     <PsychZoomed
      data={displayFullPsychometric}
      bgColor={psychBg}
      handleClosePopUp={() => {
        setDisplayFullPsychometric(null)
     }} />   }

     

     </div>
  )
}

export default StatsPsychometrics
