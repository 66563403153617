import React, { useEffect, useState } from 'react';
import { AiFillCaretDown } from "react-icons/ai"
import { useDispatch } from 'react-redux';
import { filtered_searched_policy_cat } from '../../../redux/Slices/sharedUseEffectSlice';
import { interestOptions } from '../../../mockups/User/interests';

const DashCollapseList = () => {
    const dispatch = useDispatch()
    const [, setIsTopicCollapsed] = useState(true);
    const [isDataCollapsed, setIsDataCollapsed] = useState(true);
    const [, setIsTypeCollapsed] = useState(true);
    const [filteredData, setFilteredData] = useState([])
    const policy_categories = interestOptions

    const handleChange = (e, index) => {
        if(e && index){
            if(e.target.checked){ // If the target value is checked
                setFilteredData((prevValue) =>{
                    return [...prevValue,policy_categories[index]]
                })

            }else{ // If the target value is unchecked checked
                // find the index of the value from existing array
                setFilteredData((prevValue) => {
                    return prevValue.filter((item) => item !== policy_categories[index]);
                });
            }

        }


    }

    useEffect(() => {

            dispatch(
                filtered_searched_policy_cat({
                    searched_policy_cat: filteredData
                })
            )
               // eslint-disable-next-line react-hooks/exhaustive-deps
    },[filteredData])


    const toggleCollapse = (section) => {
        switch (section) {
            case "topic":
                setIsTopicCollapsed((prev) => !prev);
                break;
            case "data":
                setIsDataCollapsed((prev) => !prev);
                break;
            case "type":
                setIsTypeCollapsed((prev) => !prev);
                break;
            default:
                break;
        }
    };

    const collapseStyles = {
        collapse: {
            maxHeight: '0',
            overflow: 'hidden',
            transition: 'max-height 0.3s ease-out',
        },
        collapseButton: {
            cursor: 'pointer',
        },
        collapseOpen: {
            maxHeight: '1000px',
        },
    };

    return (
        <div className='space-y-3'>

            <div className='border-b-2 border-white'>
                <div className='flex justify-between lg:h-[64px] items-center p-0'
                    style={collapseStyles.collapseButton} onClick={() => toggleCollapse("data")}
                >
                    <p className='text-white font-nunito lg:text-[20px] not-italic font-medium leading-normal'>
                        Policy Category
                    </p>
                    <button type='button' className={isDataCollapsed? `rotate-0`:`rotate-180`}>
                    <AiFillCaretDown className="my-2 text-white hover:text-lime-600 text-2xl" />
                    </button>
                </div>
                <div className='p-0'
                    style={{
                        ...collapseStyles.collapse,
                        ...(isDataCollapsed ? {} : collapseStyles.collapseOpen),
                    }}
                >
                 {policy_categories.map((item, idx) => {
                    return (
                        <div key={idx} className='flex justify-between p-1'>
                        <p className='text-white font-nunito text-[16px] not-italic font-medium leading-normal'>
                        {item}
                        </p>
                        <input
                        value={item}
                        onChange={(e) => {
                            handleChange(e, idx)
                        }}
                         type="checkbox"
                        className="cursor-pointer w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    )
                })}




                </div>
            </div>

{/*
            <div className='border-b-2 border-white'>
                <div className='flex justify-between lg:h-[64px] items-center p-0'
                    style={collapseStyles.collapseButton} onClick={() => toggleCollapse("type")}
                >
                    <p className='text-white font-nunito lg:text-[20px] not-italic font-medium leading-normal'>
                        Policy Type
                    </p>
                    <button type='button' className={isTypeCollapsed? `rotate-0`:`rotate-180`}>
                    <AiFillCaretDown className="my-2 text-[#474B7D] hover:text-lime-600 text-2xl" />
                    </button>
                </div>
                <div className='p-0'
                    style={{
                        ...collapseStyles.collapse,
                        ...(isTypeCollapsed ? {} : collapseStyles.collapseOpen),
                    }}
                >
                    <div className='flex justify-between p-1'>
                        <p className='text-white font-nunito text-[16px] not-italic font-medium leading-normal'>
                            Gun Control
                        </p>
                        <input type="checkbox" className="cursor-pointer w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className='flex justify-between p-1'>
                        <p className='text-white font-nunito text-[16px] not-italic font-medium leading-normal'>
                            Oil Prices
                        </p>
                        <input type="checkbox" className="cursor-pointer w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className='flex justify-between p-1'>
                        <p className='text-white font-nunito text-[16px] not-italic font-medium leading-normal'>
                            Clean Water
                        </p>
                        <input type="checkbox" className="cursor-pointer w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                    <div className='flex justify-between p-1'>
                        <p className='text-white font-nunito text-[16px] not-italic font-medium leading-normal'>
                            Labor Laws
                        </p>
                        <input type="checkbox" className="cursor-pointer w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default DashCollapseList;
