export const showPsychometricDataToUser = (event,cy,previousTapTarget, setFeedBack ) => {
    
  setFeedBack("")
    // Clear previous styles
    if (previousTapTarget) {
      previousTapTarget.style('label', ''); // Clear the label
      previousTapTarget.style({ // Reset to default
        'line-color': '', 
        'width': '',
        'color': '', 
        'text-background-color': '', 
        'text-background-shape': '', 
        'text-background-padding': '', 
        'font-family': '', 
        'font-size': '', 
        'text-margin-y': '' 
      });
      
  
      // If it was an edge, reset the connected nodes
      if (previousTapTarget.isEdge()) {
        cy.getElementById(previousTapTarget.data('source')).style('background-color', '');
        cy.getElementById(previousTapTarget.data('target')).style('background-color', '');
      }
    }
  
    // If background is clicked, just clear and return
    if (event.target === cy) {
      previousTapTarget = null;
      return;
    }
  
    // Handle edge tap
    if (event.target.isEdge()) {
      // The data contained in the clicked edge 
      const edgeData = event.target.data();

      // Get the node that has an id equal to the source from the 
      const {label, since} = edgeData;
  
      const sourceNode = cy.getElementById(edgeData?.source);
      const targetNode = cy.getElementById(edgeData?.target);
  
      if (sourceNode.length > 0 && targetNode.length > 0) {
        // Get the source node data
        const sourceNodeData = sourceNode.data();

        // Get the target node data
        const targetNodeData = targetNode.data();
  
        sourceNode.style({
          'background-color': '#3FA2F6',
        });
        targetNode.style({
          'background-color': '#3FA2F6',
        });
  
        event.target.style({
          "label": `${sourceNodeData?.name} ${label} ${targetNodeData?.name}, since ${since}`,
          "text-transform": "lowercase",
          'line-color': '#96C9F4',
          'width': 2,
          "color": "#EEE",
          "text-background-color": "#3FA2F6",
          "text-background-shape": "rectangle",
          "text-background-padding": "4px",
          "font-family": "Nunito",
          "font-size": "14px",
          "text-margin-y": "10px"
        });
  
        previousTapTarget = event.target;
        return previousTapTarget
      } else {
        setFeedBack("Source or target node not found");
      }
    }
  }