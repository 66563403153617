import React from 'react'
import { useSelector } from 'react-redux'
import DashTrendingDebates from './DashTrendingDebates'
import DashUserDebateTopics from './DashUserDebateTopics'

function DashDebateRoom({whatToDisplay}) {

const loadingStatus = useSelector(state => state.pagenation.loading)
  return (
              <div className='pt-4'>
            <h1 className='hidden md:block mb-5 text-white font-nunito 
            md:text-xl lg:text-[36px] not-italic font-bold leading-normal'>
            {whatToDisplay === "policy-cards" && "Trending Policies" }
            </h1>
            {loadingStatus
            ?
            <div className='flex item-center justify-center h-full w-full'>
            <div
            className="my-4 animate-spin rounded-full h-16 w-16
            border-t-4 border-[#3C6E71]"></div>
            </div>
            :
            <div className=''>
                <DashTrendingDebates />
                <DashUserDebateTopics/>
            </div>
        }
        </div>
  )
}

export default DashDebateRoom
