import React from 'react'
import { useNavigate } from 'react-router-dom'

function Debaters({ name, profileID, userImg, stance }) {

  const navigate = useNavigate()
  return (
    <div className=''>
      <h3 className='font-semibold my-2 text-center text-base sm:text-2xl'>{name?.slice(0, 16)}</h3>
      <div className='w-full sm:w-auto flex flex-col justify-center items-center'>
        <div className='h-[50px] w-[50px] sm:h-[150px] sm:w-[150px] rounded-full'>
          <img
            src={userImg}
            className='h-full w-full rounded-full'
            alt="" />
        </div>
        <div className='m-2'>
          <p className='text-sm md:text-xl'>{stance}</p>
        </div>
      </div>

    {profileID && 
    (<div className='flex justify-center my-3 md:pr-5 cursor-pointer'>
              <button
                onClick={() => {
                  navigate(`/profile`, {
                    state: {
                      user_id: profileID
                    }
                  })

                }}
                className='px-2 md:px-4 sm:px-10 py-2 bg-[#474B7D] hover:bg-[#444770] rounded-md 
      text-xs sm:text-[16px]'>View Profile</button>
            </div>)}

    </div>
  )
}

export default Debaters