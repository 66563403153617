export const headers = (token, isMultipart = false) => {
  return {
    // Only set Content-Type for non-multipart requests
    ...(!isMultipart && { "Content-Type": "application/json" }),
    "Authorization": token ? `Bearer ${token}` : undefined,
  };
};

export const headerConfiguration = (getState, header, isMultipart = false) => {
  const currentState = getState()
  const storedToken = currentState?.persistedReducer?.auth?.token
  const accessToken = storedToken?.accessToken

  let headerConfig = header(accessToken, isMultipart)
  return headerConfig
}