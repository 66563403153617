export const readableDate = (date_in_string, removeTime) => {
    const currentDate = new Date(date_in_string);
    // Array of month names
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Get the date components
    const year = currentDate.getFullYear();
    const monthIndex = currentDate.getMonth();
    const day = currentDate.getDate().toString().padStart(2, '0');

    // Get the time components
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    if(removeTime === true){
    // Create a readable date string with the month name
    return `${monthNames[monthIndex]} ${day}, ${year}`

    }else{
    // Create a readable date and time string with the month name
    return `${monthNames[monthIndex]} ${day}, ${year}.   ${hours}:${minutes}`;
    }



}


  // Function to convert selected date and time to the time depending on timezone
  export const getDateObject = (date, time, timezone) => {
    if (!date || !time) return null;
    
    const [hours, minutes] = time.split(':').map(Number);
    const [year, month, day] = date.split('-').map(Number);
    
    // Create date in UTC
    const dateObject = new Date(Date.UTC(year, month - 1, day, hours, minutes));
    
    // If timezone is not UTC, convert to the selected timezone
    if (timezone !== 'UTC') {
      try {
        // Format the date according to the selected timezone
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          timeZone: timezone,
          hour12: false
        };
        
        return new Intl.DateTimeFormat('en-US', options).format(dateObject);
      } catch (error) {
        console.error('Error formatting date:', error);
        return 'Invalid date/timezone combination';
      }
    }
    
    // For UTC, format manually
    return dateObject.toUTCString();
  };