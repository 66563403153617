export const layoutOptions ={
    name: 'avsdf',
    randomize: true,
    nodeOverlap: 20,
    componentSpacing: 100,
    nodeSeparation: 150,
    nodeRepulsion: function( node ){ return 400000; },
    idealEdgeLength: function( edge ){ return 50; },
    edgeElasticity: function( edge ){ return 100; },
    nestingFactor: 5,
    gravity: 80,
    numIter: 1000,
    initialTemp: 200,
    coolingFactor: 0.95,
    minTemp: 1.0
  };


export const stylesheet = [
    {
      selector: 'node',
      style: {
        "background-color": "#D6589F",
        "shape": "triangle",
        "pie-size": "20px",
        "width": "20px",
        "height": "20px",
        'label': 'data(name)',
        "color": "#C4E4FF",
        "font-family": "Nunito"
      }
    },
    {
      selector: 'edge',
      style: {
        'width': 2,
        'line-color': '#0F67B1',
        'curve-style': 'bezier'
      }
    },
    {
      selector: 'node[founded]',
      style:{
        "background-color": "#D895DA",
        "shape":"rectangle"
      }
    }
  ];