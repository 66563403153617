import React from "react";
import { FAQs, Promotion, MediaBoard, Brand } from "../components";
import BriefDesc from "../components/Home/BriefDesc";
import DataSecurity from "../components/Home/DataSecurity";

const Home = () => {
  return (
    <div>
      <Brand />
      <MediaBoard />
      <Promotion />
      <BriefDesc />
      <DataSecurity />
      <FAQs />
    </div>
  );
};

export default Home;
