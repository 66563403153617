import React, { useEffect, useState } from 'react'
import Footer from '../layout/Footer'
import UserProfile from '../components/Profile/UserProfile'
import UserActivity from '../components/Profile/UserActivity'
import UserPolicyCards from '../components/Profile/UserPolicyCards'
import MarketPlace from '../components/Profile/MarketPlace'
import { useDispatch } from 'react-redux';
import { get_all_policy_card_by_location_API } from '../redux/Thunks/policyCardThunk'
import { getUserTranscriptFromAPIAsync } from '../redux/Thunks/chatBotThunk'
import ProfileDataSet from '../components/Profile/DataSets/ProfileDataSet'
import { getUserAPI } from '../store/auth/thunk'
import { useLocation } from 'react-router-dom'
import { getRandomUserDetails } from '../redux/Thunks/randomUserThunk'
import { toast } from 'react-toastify'

function Profile() {

  const dispatch = useDispatch()
  const location = useLocation()
  const [isLocationSettings] = useState(location && location.pathname === "/settings")
  const [state, setState] = useState([true, false])

  const [user, setUser] = useState({})
  const randomUserID = location.state?.user_id
  const [randomUser] = useState(randomUserID)

 

  const onClick = (index) => {

    if (index === 0 && state[0] === true) {
      setState([true, false]) //Show recent activity component on mobile
    } else if (index === 1) {
      setState([false, true]) //Show policy web component on mobile
    } else {
      setState([true, false]) //Show recent activity component on mobile
    }
  }

  const fetchUserDetails = (user) => {

      dispatch(
      get_all_policy_card_by_location_API({
        location: user?.state
      })
       )

       dispatch(
      getUserTranscriptFromAPIAsync({
        user_id: user?.id
      }))

  }
  

  useEffect(() => {
    // If user was from the "view profile" click, Get the user details
    // Else get the current logged in user details, that is ME
    if(randomUserID){
      dispatch(
          getRandomUserDetails({
            user_id : randomUser
          })
        ).then(({payload}) => {
          if(payload?.id){
          setUser(payload)
          fetchUserDetails(payload)
        }else{
          toast.error("User not found!")
        }
        })

    }else{
      dispatch(getUserAPI())
      .then(({payload}) => {
        if(payload?.verifierId){

      setUser(payload)
      fetchUserDetails(payload)
    
        }
      })

    }

    // eslint-disable-next-line 
  }, [isLocationSettings, randomUser])





  return (
    <div>
      <div className='bg-greenOnDarkMode text-white px-3 py-5 sm:p-5 mt-[100px]'>
        <UserProfile />

        <div className='hidden lg:flex justify-center flex-wrap pl-6 pr-4'>
          <div className='my-5 w-full lg:w-[45%]'>
          <UserActivity />
          <MarketPlace />
          </div>
          <div className='my-5 bg-greenOnDarkMode text-white w-[55%] px-4'>
            {(user && user?.verifierId) && <UserPolicyCards />}
            <ProfileDataSet />
          </div>
        </div>

        <div className='flex flex-col lg:hidden w-full'>
          <div>
            {state[0]
              ?
              <UserActivity
                isMobileClicked={state}
                onClick={onClick}
              />
              : 
              ((user && user?.verifierId) && 
              <UserPolicyCards
                isMobileClicked={state}
                onClick={onClick}
              />)
              }
          </div>


          <ProfileDataSet />
          <MarketPlace />
        </div>


      </div>
      <Footer />
    </div>
  )
}

export default Profile
