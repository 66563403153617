import React from 'react';
import { TiTick } from "react-icons/ti";
import { MdOutlineCancel } from "react-icons/md";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const IconButton = ({confirmClick, cancelEditing, index, backgroundColor}) => {
return (
    <div className={`${backgroundColor} rounded`}>
    <MdOutlineCancel
     onClick={() => {
        cancelEditing(index)
    }
    }
    className="mx-2 my-2.5 text-xl text-red-700  inline cursor-pointer"
    />
    {/* Cancel Operation icon */}
    <TiTick onClick={confirmClick}
     className="mx-2 my-2.5 text-xl  text-green-700 inline cursor-pointer"
     />
    {/* continue Operation icon */}
    </div>

);
}

export default IconButton;


export const ButtonChoose = ({onNo, onYes, picked, greenText, redText}) => {
    return  <div className='flex space-x-4 my-3'>
            <button 
            type='button'
            onClick={onYes}
            disabled={picked}
            className={`bg-[#7AA65F] rounded-lg py-2 px-8 relative ${picked && "cursor-not-allowed"}`}>
            {greenText ? greenText : "Yes"}
            {picked && <IoMdCheckmarkCircleOutline className='absolute top-0 right-1'/>} 
            </button>

            <button 
            type='button'
            onClick={onNo}
            disabled={!picked}
            className={`bg-[#F66262] rounded-lg py-2 px-8 relative ${!picked && "cursor-not-allowed"}`}>
            {redText ? redText : "No"}
            {!picked && <IoMdCheckmarkCircleOutline className='absolute top-0 right-1'/>} 
            </button>
        </div>
}
