import React, { useState } from 'react';
import { CiMicrophoneOn } from "react-icons/ci";
import { VscSend } from "react-icons/vsc";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { userInfo } from '../../../redux/Selectors/selectors';
import { createChatTitle, createTranscriptID, postUserNeed } from '../../../redux/Thunks/chatBotThunk';
import { useNavigate } from 'react-router-dom';


const DashSearchBox = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [prompt, setPrompt] = useState("")

    let userID;
    const user = useSelector(state => userInfo(state))
    if (user) {
        userID = user.id;
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        toast.loading("Processing...")
        try {
            const postTranscript = await dispatch(
                createTranscriptID({
                    accessToken: user && user.token,
                    user: userID,
                })
            ).unwrap()
            if (postTranscript) {
                setPrompt("");
            }      // Dispatch the user prompt and the transcript_id from the postTranscript result
            const isFulfilled = await dispatch(
                postUserNeed({
                    accessToken: user && user.token,
                    transcriptID: postTranscript.transcript_id,
                    prompt, //User Input
                    user_id: userID
                })
            ).unwrap()
            // And at the same time create a chat Title
            const chatTitle = await dispatch(
                createChatTitle({
                    accessToken: user && user.token,
                    transcriptID: postTranscript.transcript_id,
                    prompt,
                })
            ).unwrap()

            // If user gets a successful response, navigate to the transcript page
            if (isFulfilled && chatTitle) {
                // Then switch user to a url by ID
                toast.dismiss()
                const newPageURL = `/chat/${postTranscript.transcript_id}`; // new URL with the parameter
                navigate(newPageURL); //navigate
            }

        } catch (error) {
            if (error) {
                toast.error("Ooops, something went wrong!")
                toast.dismiss()
            }
        }

    }


    return (
        <form onSubmit={handleSubmit}>
            <div className="relative">
                <input
                    className="h-[33px] lg:h-[70px] text-[14px] lg:text-[24px] w-[290px] lg:w-[868px] pl:3 pr-12 lg:pl-8 lg:pr-32 bg-white rounded-[10px] lg:rounded-[30px] border-[1px solid #6B846B]
                    focus:ring-blue-50 focus:border-blue-50 block"
                    type="text"
                    value={prompt}
                    onChange={(e) => {
                        setPrompt(e.target.value)
                    }}
                    id="searchKey"
                    placeholder="Type search keyword..."
                    required
                />
                <button
                    className="absolute inset-y-0 right-0 pr-7 lg:pr-20"
                    type="submit"
                >
                    <CiMicrophoneOn className='lg:text-3xl' />
                </button>
                <button
                    className="absolute inset-y-0 right-0 pr-2 lg:pr-7"
                    type="submit"
                >
                    <VscSend className='lg:text-3xl ' />
                </button>
            </div>
        </form>
    );
};

export default DashSearchBox;
