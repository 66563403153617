import { createAsyncThunk } from '@reduxjs/toolkit';
import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK } from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import Web3 from "web3";
import { NAME } from './constants';
import { makeApiRequest } from '../../components/common/API_request';
import { SERVER_URL } from '../../config/config';
import { headers, headerConfiguration } from './headerConfig';
import { toast } from 'react-toastify';
import api from '../api/api';


let webNetwork =  WEB3AUTH_NETWORK.SAPPHIRE_MAINNET

const clientId = process.env.REACT_APP_CLIENT_ID;
const MODE = process.env.REACT_APP_ENV
if(MODE === "development"){
  webNetwork = WEB3AUTH_NETWORK.SAPPHIRE_DEVNET
}

const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: "0x1",
  rpcTarget: "https://rpc.ankr.com/eth",
  displayName: "Ethereum Mainnet",
  blockExplorerUrl: "https://etherscan.io/",
  ticker: "ETH",
  tickerName: "Ethereum",
};

const privateKeyProvider = new EthereumPrivateKeyProvider({
  config: { chainConfig: chainConfig },
});

const web3auth = new Web3Auth({
  clientId,
  web3AuthNetwork: webNetwork,
  privateKeyProvider: privateKeyProvider,
});

export const initializeWeb3Auth = createAsyncThunk(
  `${NAME}/initializeWeb3Auth`,
  async () => {
    try {
      await web3auth.initModal();
      return web3auth.connected;
    } catch (error) {
      // console.error(error)
      // return (error?.message);
    }
  }
);

export const loginWeb3Auth = createAsyncThunk(
  `${NAME}/loginWeb3Auth`,
  async (_, {dispatch}) => {

    try {
      const web3authProvider = await web3auth.connect();
      const userInfo = await web3auth.getUserInfo();
      const web3 = new Web3(web3authProvider);
      const address = (await web3.eth.getAccounts())[0];
      const balance = web3.utils.fromWei(
        await web3.eth.getBalance(address),
        "ether"
      );
      const chainId = await web3.eth.getChainId();

      const url = `${SERVER_URL}/api/login/`
      const headers = {
        "Content-Type": "application/json",
      };
      const body = {
        ...userInfo,
        token_from_web3Auth: userInfo.idToken,
        wallet_address: address,
        balance : parseFloat(balance),
        chain_id: `${chainId}`,
        phone_number: "",
        country: "",
        state: "",
        city: "",
      }

      if(userInfo?.verifierId){
        const response = await makeApiRequest(url, "POST", headers, body);

        if(response?.accessToken){
          await dispatch(getUserAPI({token: response?.accessToken }));
        }
       
        return response
      }

      
    } catch (error) {
      console.error(error)
      return (error?.message);
    }
  }
);

export const getUserAPI = createAsyncThunk(
  `${NAME}/getUserAPI`,
  async (payload, {getState, dispatch}) => {

    let headerConfig = headerConfiguration(getState, headers)
    if(payload?.token){
        headerConfig =  {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${payload?.token}`,
        }
    }
    
    try {
      const url = `${SERVER_URL}/api/me/`
      const response = await api(url, "GET", headerConfig, null, dispatch);
      return response
    } catch (error) {
      // console.error(error)
      return (error?.message);
    }
  }
);

export const accessTokenRefresh = createAsyncThunk(
  `${NAME}/accessTokenRefresh`, 
  async (_, {getState, dispatch}) => {
    const currentState = getState()
    const storedToken = currentState?.persistedReducer?.auth?.token
    const refreshToken = storedToken?.refreshToken

    const headerConfig =  {
      "Content-Type": "application/json",
    }

    try {
      const url = `${SERVER_URL}/api/refresh/`
      const response = await api(url, "POST", headerConfig, {refreshToken}, dispatch)
      return response
    } catch (error) {
      // console.error(error)
      return (error?.message);
    }

  }
)

export const updateUserDetails = createAsyncThunk(
   `${NAME}/updateUserDetails`,
  async({user_id, email, state, city, country, phone_number, wallet_address, bio}, {getState}) => {

    const headerConfig = headerConfiguration(getState, headers)

    try {
      toast.loading("Processing")
      const url = `${SERVER_URL}/api/me/update/${user_id}/`
      const body = {
        email,
        state,
        city,
        country,
        phone_number,
        wallet_address,
        bio
      }
      const request = await fetch(url,{
        method :"PUT",
        headers: headerConfig,
        body: JSON.stringify(body)
      })

      toast.dismiss()

      const response = await request.json()
      if(request.ok){
        toast.success(response?.message)
      }else{
        toast.error(response?.message)
      }

      return response
    } catch (error) {
      // console.error(error)
      return (error?.message);
    }
  }
)


export const updateImage = createAsyncThunk(
  `${NAME}/updateImage`,
 async({user_id, new_image_url}, {getState}) => {

  const headerConfig = headerConfiguration(getState, headers)

   try {

     const url = `${SERVER_URL}/api/me/updateImage/${user_id}/`
     const request = await fetch(url,{
       method :"PUT",
       headers: headerConfig,
       body: JSON.stringify({new_image_url})
     })

     toast.dismiss()

     const response = await request.json()
     if(request.ok){
       toast.success(response?.message)
     }else{
       toast.error(response?.message)
     }

     return response
   } catch (error) {
      // console.error(error)
      return (error?.message);
   }
 }
)

export const logout = createAsyncThunk(
  `${NAME}/logout`,
  async () => {
    try {
      await web3auth.logout();
      return true;
    } catch (error) {
      // console.error(error)
      // return (error.message);
    }
  }
);