import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Debaters from './Debaters'
import { useDispatch, useSelector } from 'react-redux'
import { getAllDebates } from '../../redux/Thunks/debatesThunk'


function DashTrendingDebates() {

  const dispatch = useDispatch()
  const debaters = useSelector(state => state.debates.allDebates)

  

  useEffect(() => {
    dispatch(
      getAllDebates()
    )
  }, [dispatch])

  return (
    <>
      {debaters.length > 0 &&
      (
        <div className={`bg-nightModeDark_600 relative rounded-2xl text-white mb-10 py-5 px-2 sm:px-5`}>

<p className='absolute -top-3 -right-5 rotate-45 text-5xl'>🔥</p>
<p className='text-xl sm:text-2xl font-bold mb-5'>Debate Room (Trending)</p>

{debaters && 

<div className='bg-nightModeDark flex flex-col sm:justify-center py-5 px-2.5 sm:px-4'>

        <div className='flex justify-around flex-col sm:px-4 mt-5'>
        <h3 className='text-2xl font-bold'>{debaters[0]?.topic}</h3>
          <p className='italic font-light my-1'><i>Created by:</i> {debaters[0]?.author?.username}</p>
          <p className='mt-4'>Remaining time: {debaters[0]?.duration}</p>
        </div>

        <div className='flex justify-between sm:justify-center my-5'>
        <Debaters
        name={debaters[0]?.supporting[0]?.name}
        profileID={debaters[0]?.supporting[0]?.id}
        userImg={debaters[0]?.supporting[0]?.profileImage}
        stance="Supporting"
         />

        <div className='flex justify-center items-center mx-3 md:mx-8  md:h-[270px]' >
        <h3 className='text-[18px] sm:text-2xl font-bold'>Vs</h3>
        </div>
        
        <Debaters
        name={debaters[0]?.opposing[0]?.name}
        profileID={debaters[0]?.opposing[0]?.id}
        userImg={debaters[0]?.opposing[0]?.profileImage}
        stance="Opposing"
         />
        </div>



  <Link to={`/debates/${debaters[0]?.debate_id}`}>
  <div className='flex justify-center md:pr-5'>
  <button className='w-full sm:w-3/4 px-4 sm:px-10 py-2 bg-[#474B7D] hover:bg-[#444770] rounded-md 
  text-sm sm:text-[16px]'>Enter Debate Room</button>
  </div>
  </Link>
    </div>
    }




</div>
      )}
    </>
  )
}

export default DashTrendingDebates
