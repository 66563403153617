const stylesheet = [
    {
      selector: 'node',
      style: {
        'background-color': '#0b381b', // Tailwind green-500
        'label': 'data(name)',
        'color': '#ffffff',
        'text-outline-color': '#000000',
        'text-outline-width': 2,
        'font-size': 5,
        'text-valign': 'center',
        'text-halign': 'center',
        'text-wrap': 'wrap',
        'text-max-width': '20px',
        // 'width': 80,
        // 'height': 80,
        'border-color': '#122218', 
        'border-width': 3,
        'border-opacity': 0.8,
        'shape': 'ellipse',
      }
    },
    {
      selector: 'edge',
      style: {
        'width': 2,
        'font-size': 7,
        'label': 'data(weight)',
        'color': '#ffffff',
        'text-outline-color': '#000000',
        'text-outline-width': 2,
        'line-color': '#aa7832', // Tailwind green-400
        'target-arrow-color': '#aa7832',
        'target-arrow-shape': 'triangle',
        'curve-style': 'bezier'
      }
    },
    {
      selector: '.connected_nodes',
      style: {
        'background-color': '#313a35', // Tailwind yellow-500
        'border-color': '#a16207',
      }
    }
  ];
  
  const layout = {
    name: 'avsdf',
    idealEdgeLength: 100,
    nodeOverlap: 20,
    refresh: 20,
    fit: true,
    padding: 30,
    randomize: false,
    componentSpacing: 100,
    nodeRepulsion: 400000,
    edgeElasticity: 100,
    nestingFactor: 5,
    gravity: 80,
    numIter: 1000,
    initialTemp: 200,
    coolingFactor: 0.95,
    minTemp: 1.0
  };




  
export {stylesheet, layout}