import React, { useEffect } from 'react';
import ChatSide from '../components/ChatBox/ChatSide';
import ChatBoxByTranscript from '../components/ChatBox/ChatBoxByTranscript';
import { useDispatch, useSelector } from 'react-redux';
import { chatSideOpen, toggleChatSideBar } from '../redux/Slices/sharedUseEffectSlice';
import { hasTokenExpired } from '../components/common/tokenExpired';
import { authTokens } from '../redux/Selectors/selectors';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

const EachTranscriptPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const token = useSelector(authTokens)

  const isExpired = hasTokenExpired(token?.refreshToken)

  const sideBar = useSelector(state => chatSideOpen(state))
  const closeChatSide = () => {
    dispatch(toggleChatSideBar())
  }

  useEffect(() => {
    if (isExpired) {
      navigate("/signin")
    }
  }, [navigate, isExpired])



  return (
    <div className='relative h-screen flex flex-wrap font-nunito font-medium box-border ' >

<button
        className={`${sideBar ? "block" : "hidden"} lg:hidden text-3xl fixed top-2 left-[81%] z-10`}
        onClick={closeChatSide}>
        <FaTimes className='text-chatTipBackground' />
      </button>
      
      <ChatSide />
      <ChatBoxByTranscript sidebarOpen={sideBar} />
    </div>
  );
}

export default EachTranscriptPage;
