export const userRecentActivities = [{
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "This definitely needed a little more work before it came out.",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "author": 3,
        "parent": null,
        "username": "Mike Mike"
    },
    {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "This definitely needed a little more work before it came out.",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "author": 3,
        "parent": null,
        "username": "Mike Mike"
    }, {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "Hello",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "author": 3,
        "parent": null,
        "username": "Mike Mike"
    }, {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "Hello",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "author": 3,
        "parent": null,
        "username": "Mike Mike"
    }, {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "This definitely needed a little more work before it came out.",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "author": 3,
        "parent": null,
        "username": "Mike Mike"
    }, {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "This definitely needed a little more work before it came out.",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "author": 3,
        "parent": null,
        "username": "Mike Mike"
    }, {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "Hello",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "author": 3,
        "parent": null,
        "username": "Mike Mike"
    }, {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "Hello",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "author": 3,
        "parent": null,
        "username": "Mike Mike"
    },
]

export const transcriptsData = [
    {
        transcript_id: "49ce720d-b04d-4d7e-b168-7f4c113e64a5",
        title: "Unlocking the Power of Hello",
        created_at: "2024-04-18T18:12:47.905597Z",
        user: 2
    },
    {
        transcript_id: "49ce720d-b04d-4d7e-b168-7f4c113e64a5",
        title: "Unlocking the Power of Hello",
        created_at: "2024-04-18T18:12:47.905597Z",
        user: 2
    },
]


export const marketPlaceData = [
    {
        head:"Most Visited Topics",
        child1:"Taxes",
        child2:"Health insurance"
    },
    {
        head:"Comments & Replies",
        child1:"12 comments",
        child2:"27 replies"
    },
    {
        head:"Profile Views",
        child1:"27 views in the past day",
        child2:"3,125 views total"
    },
    {
        head:"Number of Policies Impacted",
        child1:"3 policies changed",
        child2:"2 policies recalled"
    },
    {
        head:"# of Interactions a Day",
        child1:"2.3 interactons",
        child2:""
    },
    {
        head:"Amount of Hours Spent",
        child1:"17 hours total",
        child2:"1.2 hours a day"
    },
    {
        head:"Length of Conversations",
        child1:"214 hours total",
        child2:"1.2 hours a day"
    },


]


export const avatarColors = [
    "#C40C0C", "#FF6500", "#10439F", "#1679AB", "#003C43", "#481E14",
    "#D20062", "#E8751A", "5F5D9C"
]
