import React from 'react'
import ChatRoom from './ChatRoom'

const DebateUpdate = () => {
  return (
    <div className='bg-[#101010] rounded-lg w-full lg:w-[50%] '>
    <div className='p-2 m-2 md:p-5 md:m-5'>
        <h1 className='font-bold font-nunito text-base md:text-3xl'>Debate Updates</h1>
    </div>
    <div className=' p-2 md:p-5 md:m-2'>
     <ChatRoom/>
    </div>


    </div>
  )
}

export default DebateUpdate