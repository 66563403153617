import CytoscapeComponent from 'react-cytoscapejs';
import { layout, stylesheet } from './stylesheet';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { showPsychometricDataToUser } from './func';



const GraphNetwork = ({data, fullscreen, newBg}) => {
  const cyRef = useRef(null);
  const [feedBack, setFeedBack] = useState("")


  const setConnectedClass = (cy) => {
    if (cy) {
      cy.edges().connectedNodes().addClass('connected_nodes');
    }
  };

  const userInteraction = (cy) => {
    let previousTapTarget = null;

    cy.on("tap", (event) => {
      const result = showPsychometricDataToUser(event, cy, previousTapTarget, setFeedBack)
      previousTapTarget = result
    });

    // MAKE THE MOUSE A POINTER IF IT IS ON A NODE OR EDGE
    cy.on('mouseover', (event) => {
      if(event.cy.container()) {
        event.cy.container().style.cursor = 'pointer';
      }
    })
    
    // RETURN THE MOUSE TO DEFAULT
    cy.on('mouseout', (event) => {
      if(event.cy.container()) {
        event.cy.container().style.cursor = 'default';
      }
    })


  }

  useEffect(() => {
    if (cyRef.current) {
      const cy = cyRef.current;
      cy.on('layoutstop', () => setConnectedClass(cy));
      setConnectedClass(cy);
      userInteraction(cy);

    }
  }, [data]);

  return (
    <div className=''>
    <p>{feedBack}</p>
      <CytoscapeComponent
        elements={CytoscapeComponent.normalizeElements(data)}
        stylesheet={stylesheet}
        layout={layout}
        style={{ 
                 width: fullscreen ? '90vw' : "500px",
                 height: fullscreen ? '80vh' : "500px", 
                 backgroundColor: newBg ? newBg : "#e4e4e7", 
               }}
        cy={(cy) => { cyRef.current = cy }}
        minZoom={0.0}
      />
    </div>
  );
};

export default GraphNetwork;