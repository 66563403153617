import React from 'react'
import { useNavigate } from 'react-router-dom'
import Debaters from '../Debaters'

function DebateTeam({debaters, stance}) {

    const navigate = useNavigate()

    function viewProfile(ID){
      if(ID){navigate(`/profile`, {state: {
        user_id : ID
      }})
    }
  }




  return (
    <div className='relative'>
    <div 
    title='click to view profile'
    className="cursor-pointer"
    onClick={() => {
      viewProfile(debaters[0]?.id)
    }}>
    <Debaters
      name={debaters[0]?.name}
      userImg={debaters[0]?.profileImage}
      stance={stance}
      />
    </div>
    {debaters?.slice(1)?.map((item, idx) => {
      return (
    <div key={idx} className={`${idx === 0 ? "top-[15%]" : "top-[55%]"} 
     ${stance === "Opposing" ? "-right-[35%]" : "-left-[35%]" }
     absolute z-[3] h-[25px] w-[25px] sm:h-[70px] sm:w-[70px] rounded-full mb-4}`}>
    
 <p className='text-[10px] text-center'>{item?.name?.slice(0,15)}</p>
  <img
    title='click to view profile'
    onClick={() => {
      viewProfile(item?.id)
    }} 
    src={item?.profileImage}
    className='cursor-pointer h-full w-full rounded-full'
    alt="" />
  </div>
      )
    })}
      </div>
  )
}

export default DebateTeam