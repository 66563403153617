import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getEachDiscussion } from '../../redux/Thunks/discussionsThunk'


const DiscussionsBoard = () => {

  const { discussion_id } = useParams()

    const dispatch = useDispatch()
    const discussionData = useSelector(state => state.discussions.oneDiscussion)


    useEffect(() => {
        dispatch(
          getEachDiscussion({
            discussion_id,
          })
        )

      },[discussion_id, dispatch])


  
  return (
    <>   


    </>
  )
}

export default DiscussionsBoard