import React from 'react'

function SearchBoxResult({results, pickUser, role, errorMsg, close}) {
  return (

        <div className={`w-full md:w-3/4 rounded-xl absolute z-[3] 
        ${errorMsg ? "top-[110px]" : "top-[78px]"}
         bg-nightModeDark_200  text-white`}>
        {results?.map((item) => {
            
            return (<div key={item?.id} 
            onClick={() => {
                if(role === "opps"){
                    pickUser(item, "opps")
                    close()
                }else{
                    pickUser(item, "supp")
                    close()
                }
                
            }} 
            className='flex justify-start px-3 my-3 cursor-pointer'>
            <div className='w-[30px] h-[30px]'>
                <img src={item?.profileImage}
                    alt={`${item?.name} avatar`}
                    className='h-full w-full rounded-full' />
            </div>
            <p className='ml-2'>{item?.name}</p>
        </div>)

            
        })}
        </div>

  )
}

export default SearchBoxResult