import React, { useState } from 'react';
import { PasswordResetInput } from '../common/input';

const DateTimePicker = ({onChange, value}) => {

  const [isTimeVisible, setIsTimeVisible] = useState(false);
  
  const timezones = [
    'UTC',
    'America/New_York',
    'America/Los_Angeles',
    'Europe/London',
    'Asia/Tokyo',
    'Australia/Sydney',
    'Europe/Paris'
  ];

  // Generate time options in 30-minute intervals
  const timeOptions = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute of [0, 30]) {
      timeOptions.push(`${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`);
    }
  }

  return (
    <div className="p-4 bg-white text-black rounded-lg shadow-md" style={{ maxWidth: '400px' }}>
      <div>
        {/* Date and Time Section */}
        <div className='flex justify-between'>
          {/* Date Input */}
          <div className='flex-[2] mr-4'>
          <PasswordResetInput
          label="Date"
          type="date"
          name='date'
          value={value?.date}
          handleChange={onChange}
          whiteBg={true}
          />
          </div>

          {/* Time Input */}
          <div className='flex-1 relative' onClick={() => setIsTimeVisible(!isTimeVisible)}>
          <PasswordResetInput
          label="Time"
          name='time'
          value={value?.time}
          handleChange={onChange}
          whiteBg={true}
          placeholder="HH:mm"
          />
            
            {/* Time Dropdown */}
            {isTimeVisible && (
              <div 
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  right: 0,
                  maxHeight: '200px',
                  overflowY: 'auto',
                  backgroundColor: 'white',
                  border: '1px solid #e2e8f0',
                  borderRadius: '0.375rem',
                  zIndex: 10,
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)'
                }}
              >
                {timeOptions.map((timeOption) => (
                  <div
                    key={timeOption}
                    onClick={(e) => {
                      const data = {
                        name: "time",
                        value: timeOption
                      }

                      onChange(e, data);
                      setIsTimeVisible(false);
                    }}
                    className="p-2 hover:bg-blue-50 cursor-pointer"
                  >
                    {timeOption}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Timezone Section */}
        <div>
          <label className="">Timezone</label>
          <select
            value={value?.timezone}
            name='timezone'
            onChange={onChange}
            className={`my-2 w-full px-4 py-2.5 outline-none
     text-black border-gray-600 placeholder:text-gray-600 placeholder:text-sm
      border-1 rounded-lg`}
          >
            {timezones.map((tz) => (
              <option key={tz} value={tz}>
                {tz}
              </option>
            ))}
          </select>
        </div>

        {/* Selected DateTime Display */}
        {value?.date && value?.time && (
          <div className="mt-4 p-2 bg-gray-50 rounded text-sm">
            Selected: {value?.date} {value?.time} {value?.timezone}
          </div>
        )}
      </div>
    </div>
  );
};

export default DateTimePicker;