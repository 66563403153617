import React from 'react'


const TextArea = ({value, onChange}) => {
  return (
    <form className=' mt-4'>
    <label className='relative'>
    <textarea
            name='message'
            value={value}
            onChange={onChange}
            rows={4}
            required
            placeholder=''
            className='w-full bg-white px-5 py-2 min-h-14 rounded-lg  text-lg text-black-800 shadow-black-200 shadow-2xl focus:outline-none'
         />
                 <button className='absolute bg-[#7AA65F] rounded-lg text-sm md:text-xl
                  py-3 px-7 text-white bottom-3 right-2'>
            send
        </button>
    </label>



    <div className='mt-2'>
      <p className='font-nunito italic text-sm md:text-xl'>Not all arguments will show up here. Only the most upvoted points will be saved here. Feel free to use the discussion forum below to share your thoughts.</p>
    </div>
    </form>
  )
}

export default TextArea