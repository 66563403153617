import React, { useEffect } from 'react'
import Summary from '../components/DiscussionRoom/summaries/summary';
import DebateUpdate from '../components/DebateRoom/Debate Files/DebateUpdates/DebateUpdate';
import UpdateSummary from '../components/DebateRoom/Debate Files/DebateSummaries/UpdateSummary';
import DebateCommentSection from '../components/DebateRoom/Debate Files/DebateCommentSection';
import Footer from '../layout/Footer';
import DebateRoom from '../components/DebateRoom/Debate Files/DebateRoom';
import { useDispatch, useSelector } from 'react-redux';
import { getEachDebate } from '../redux/Thunks/debatesThunk';
import { useParams } from 'react-router-dom';

// import Footer from '../../layout/Footer'
// import DebateRoom from './Debate Files/DebateRoom'
// import Summary from '../DiscussionRoom/summaries/summary'
// import DebateUpdate from './Debate Files/DebateUpdates/DebateUpdate'
// import UpdateSummary from './Debate Files/DebateSummaries/UpdateSummary'
// import DebateCommentSection from './Debate Files/DebateCommentSection'


const DebateCenter = () => {
  const {debate_id} = useParams()
  const dispatch = useDispatch()
  const debate = useSelector(state => state.debates.oneDebate)


  useEffect(() => {
    dispatch(
      getEachDebate({
        debate_id
      })
    )
  }, [dispatch, debate_id])
  return (
    <div className='bg-nightModeDark text-white min-h-max'>
    <div className='flex flex-col lg:flex-row gap-4 m-1 md:m-4'>
    <DebateRoom
      debateDetails={debate}
    />
    <Summary/>
    </div>

    <div className='flex flex-col lg:flex-row gap-4 mx-1 my-2 md:m-4'>
      <DebateUpdate/>
      {/* <Opinion/> */}
      <UpdateSummary/>
    </div>
      <DebateCommentSection/>
      <Footer/>
    </div>
  )
}

export default DebateCenter;