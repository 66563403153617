export const testData = {
    "results": [
      {
        "columns": ["n", "r", "m"],
        "data": [
          {
            "row": [
              {
                "id": 1,
                "labels": ["Person"],
                "properties": {
                  "name": "Alice",
                  "age": 30
                }
              },
              {
                "type": "KNOWS",
                "properties": {
                  "since": 2020
                }
              },
              {
                "id": 2,
                "labels": ["Person"],
                "properties": {
                  "name": "Bob",
                  "age": 32
                }
              }
            ],
            "meta": [
              {
                "id": 1,
                "type": "node",
                "deleted": false
              },
              {
                "id": 0,
                "type": "relationship",
                "deleted": false
              },
              {
                "id": 2,
                "type": "node",
                "deleted": false
              }
            ]
          },

          {
            "row": [
              {
                "id": 1,
                "labels": ["Person"],
                "properties": {
                  "name": "Alice",
                  "age": 30
                }
              },
              {
                "type": "WORKS_AT",
                "properties": {
                  "since": 2019
                }
              },
              {
                "id": 3,
                "labels": ["Company"],
                "properties": {
                  "name": "OpenPolitica",
                  "founded": 2012
                }
              }
            ],
            "meta": [
              {
                "id": 1,
                "type": "node",
                "deleted": false
              },
              {
                "id": 1,
                "type": "relationship",
                "deleted": false
              },
              {
                "id": 3,
                "type": "node",
                "deleted": false
              }
            ]
          },
          {
            "row": [
              {
                "id": 1,
                "labels": ["Person"],
                "properties": {
                  "name": "Alice",
                  "age": 30
                }
              },
              {
                "type": "KNOWS",
                "properties": {
                  "since": 2020
                }
              },
              {
                "id": 2,
                "labels": ["Person"],
                "properties": {
                  "name": "Bob",
                  "age": 32
                }
              }
            ],
            "meta": [
              {
                "id": 1,
                "type": "node",
                "deleted": false
              },
              {
                "id": 0,
                "type": "relationship",
                "deleted": false
              },
              {
                "id": 2,
                "type": "node",
                "deleted": false
              }
            ]
          },
          {
            "row": [
              {
                "id": 4,
                "labels": ["Person"],
                "properties": {
                  "name": "Mike",
                  "age": 18
                }
              },
              {
                "type": "KNOWS",
                "properties": {
                  "since": 2020
                }
              },
              {
                "id": 2,
                "labels": ["Person"],
                "properties": {
                  "name": "Bob",
                  "age": 32
                }
              }
            ],
            "meta": [
              {
                "id": 4,
                "type": "node",
                "deleted": false
              },
              {
                "id": 0,
                "type": "relationship",
                "deleted": false
              },
              {
                "id": 2,
                "type": "node",
                "deleted": false
              }
            ]
          },
                    {
            "row": [
              {
                "id": 1,
                "labels": ["Person"],
                "properties": {
                  "name": "Alice",
                  "age": 30
                }
              },
              {
                "type": "KNOWS",
                "properties": {
                  "since": 2020
                }
              },
              {
                "id": 4,
                "labels": ["Person"],
                "properties": {
                  "name": "Mike",
                  "age": 18
                }
              }
            ],
            "meta": [
              {
                "id": 1,
                "type": "node",
                "deleted": false
              },
              {
                "id": 0,
                "type": "relationship",
                "deleted": false
              },
              {
                "id": 4,
                "type": "node",
                "deleted": false
              }
            ]
          },
          
          {
            "row": [
              {
                "id": 4,
                "labels": ["Person"],
                "properties": {
                  "name": "Mike",
                  "age": 18
                }
              },
              {
                "type": "WORKS_AT",
                "properties": {
                  "since": 2014
                }
              },
              {
                "id": 3,
                "labels": ["Company"],
                "properties": {
                  "name": "OpenPolitica",
                  "founded": 2012
                }
              }
            ],
            "meta": [
              {
                "id": 4,
                "type": "node",
                "deleted": false
              },
              {
                "id": 1,
                "type": "relationship",
                "deleted": false
              },
              {
                "id": 3,
                "type": "node",
                "deleted": false
              }
            ]
          },
        ]
      }
    ],
    "errors": []
  }



