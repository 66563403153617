export default function isValidPassword(password) {
    // Check if password is at least 10 characters long
    if (password.length < 10) {
        return "Too Weak";
    }

    // Define regex patterns for required character types
    const hasUppercase = /[A-Z]/;
    const hasLowercase = /[a-z]/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    // Check for presence of each required character type
    const containsUppercase = hasUppercase.test(password);
    const containsLowercase = hasLowercase.test(password);
    const containsSpecialChar = hasSpecialChar.test(password);
    if(!containsUppercase || !containsLowercase || !containsSpecialChar ){
        return "Fair"
    }
    // Return true only if all conditions are met
    return containsUppercase && containsLowercase && containsSpecialChar;
}
