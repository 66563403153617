import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
    Home,
    Chat,
    Products,
    About,
    SignIn,
    SignUp,
    EachTranscriptPage,
    PolicyCardByID,
    EditPolicyCard,
    CreatePolicyCard,
    Dashboard,
    ForgotPassword,
    ResetPassword,
    ChangePassword,
    Profile,
    Settings,
    MarketPlace,
    CompanySignUp,
    FeedBack,
    MarketPlaceDataSearch,
    DebateCenter
} from "./pages";
import Header from "./layout/Header";
import { ChatbotIcon } from "./components";
import PrivateRoute from "./components/PrivateRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Discussions from "./components/DiscussionRoom/Discussions";



function App() {



    return (
        <>
            <BrowserRouter>
                <div
                    className="flex flex-col min-h-screen overflow-hidden relative bg-[#353535]">
                    <Header />

                    <Routes>
                        <Route path="/chat" element={<Chat />} />
                        <Route path="/chat/:transcriptID" element={<EachTranscriptPage />} />
                        <Route path="/" element={<Home />} />
                        <Route path="/products" element={<Products />} />
                        <Route path="/about" element={<About />} />
                        <Route
                            path="/dashboard"
                            element={<PrivateRoute > <Dashboard />
                            </PrivateRoute>
                            }
                        />
                        <Route path="/dashboard/:policyCardID"
                        element={<PolicyCardByID />} />

                        <Route path="/dashboard/edit/:policyCardID"
                         element={<EditPolicyCard />} />

                        <Route path="/dashboard/new"
                         element={<PrivateRoute ><CreatePolicyCard /></PrivateRoute>} />
                         
                         <Route path="/discussions/:discussion_id" element={<Discussions/>}/>
                         <Route path="/debates/:debate_id" element={<DebateCenter/>}/>



                        {/* SIGN IN AND UP ROUTES */}
                        <Route path="/signin" 
                        element={<AuthenticatedRoute><SignIn /></AuthenticatedRoute> } />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/company/signup" element={<CompanySignUp />} />

                        <Route path="/company/feedback"
                        element={<PrivateRoute ><FeedBack /></PrivateRoute>}  />

                        {/* PROFILE ROUTE */}
                        <Route path="/profile"
                         element={<PrivateRoute ><Profile /></PrivateRoute>} />

                        {/* MARKET PLACE */}
                        <Route path="/profile/market-place"
                        element={<PrivateRoute ><MarketPlace /></PrivateRoute>} />

                        <Route path="/profile/market-place/query"
                        element={<PrivateRoute ><MarketPlaceDataSearch /></PrivateRoute>} />


                        {/* SETTINGS ROUTE */}
                        <Route path="/settings"
                        element={<PrivateRoute ><Settings /></PrivateRoute>} />

                        <Route path="/settings/forgot-password"
                        element={<ForgotPassword />} />

                        <Route path="/settings/reset-password/:tokenID"
                        element={<ResetPassword />} />

                        <Route path="/settings/change-password"
                        element={<PrivateRoute ><ChangePassword /></PrivateRoute>} />
                        




                    </Routes>
                    <div className="z-[50]">
                    <ChatbotIcon />
                    </div>
                </div>
            </BrowserRouter>
        </>
    );
}

export default App;
