import React, { useState } from 'react'
import { DateRange } from 'react-date-range';
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import { readableDate } from '../../common/set_date';


function QueryByDate({ dateRange, handleChange }) {

    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className='w-full md:w-3/4 relative text-xs sm:text-[16px]'>
            <button
                type='button'
                className='w-full rounded-md py-2 sm:py-3 px-4 text-left
    text-black bg-white hover:bg-gray-100'
                onClick={() => {
                    setIsOpen(!isOpen)
                }}
            >
                <span className='font-bold mr-2'>Purchase From</span>
                {readableDate(dateRange[0]?.startDate, true)}
                <span className=''><FaCaretDown className='inline' /></span>
                <span className='font-bold mx-2'>To </span>
                {readableDate(dateRange[0]?.endDate, true)}
                <span className=''><FaCaretUp className='inline' /></span>
            </button>

            {isOpen &&
                <div className='absolute z-[1]'>
                    <DateRange
                        onChange={handleChange}
                        ranges={dateRange}
                        // color="red"
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                    />
                </div>}

        </div>

    )
}

export default QueryByDate
