import React, { useState } from 'react'
import Footer from '../layout/Footer'
import { PasswordResetInput } from '../components/common/input'

function ForgotPassword() {
    const [text, setText] = useState("")
    return (
        <div>
            <div className='flex h-[95vh] justify-center items-center '>
                <div className='bg-greenOnDarkMode text-white w-[50%] h-full
                 rounded-tl-2xl rounded-md
                 flex justify-center items-center'>
                    <div className='p-4 space-y-3 '>
                        <h3 className='font-bold text-xl'>Forgot Password?</h3>
                        <p className='text-sm'>Please enter your email to receive
                         a password reset link.</p>

                         <div className='mt-4'>
                            <PasswordResetInput
                            label="Email"
                            placeholder="Enter your account's email address"
                            value={text}
                            handleChange={(e) => {
                                setText(e.target.value)
                            }}
                            />
                            <button className='bg-updatedPurple mt-2 px-4 py-2.5
                             w-full rounded-lg hover:opacity-80'>
                                SEND LINK
                            </button>

                         </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default ForgotPassword
