import React from 'react'

import Image1 from "../../assets/img/about1.jpg"

function Intro() {
  return (
    <div>
     <section className='my-3 flex  flex-col items-center justify-center p-4 flex-wrap text-center w-full  '>
      <div className='text-white p-3 sm:py-3 w-full'>
        <h2 className='text-xl sm:text-3xl lg:text-5xl font-bold mx-2.5 my-3'>Hello! We are OpenPolitica!</h2>
        <p className=' indent-2 md:indent-8 text-[16px]  md:block sm:text-2xl text-center'>
          We are a team of driven individuals from all around the world<br className='hidden md:block'/>working on one mission: to empower the future of policymaking through<br className='hidden md:block'/> modern technology and user friendly design!
        </p>
      </div>
       <div className='flex justify-center items-center mt-4 mx-4 w-[310px] md:w-[550px]'>
        <img className='h-3/4 w-full rounded-lg' src={Image1} alt='' />
       </div>
      </section>
    </div>
  )
}

export default Intro
