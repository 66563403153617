import { accessTokenRefresh, logout } from '../auth/thunk';
import { makeApiRequest } from '../../components/common/API_request';


const api = async (url, method, headerConfig, data, dispatch) => {

    let body = null
    if(data){
        body = data
    }


    try {
      const response = await makeApiRequest(url, method , headerConfig, body);

      if (response?.message === "UnAuthorized Token") {
        // Token has expired, try to get a new Access Token
        const newAccessToken = await dispatch(accessTokenRefresh());

        const token = newAccessToken?.payload?.accessToken

        if (newAccessToken?.payload?.accessToken) {
          // Retry the original request with the new accessToken
          headerConfig['Authorization'] = `Bearer ${token}`;
          const response2 = await makeApiRequest(url, method , headerConfig, body);
          return response2
        }else {
            // Which means refreshToken has expired, likely, log user out
            await dispatch(logout());
            return
        }
      }else if(response?.message === "Log user out"){
        await dispatch(logout())
        return
      }

      return response;
    } catch (error) {
      throw error;
    }
  }


export default api;