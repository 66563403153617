import React from 'react'
import {TeamFeature} from '../common/Team_Feature'



function Team() {

    return (
        <div className='-px-4 my-4 mr-4 lg:mr-0'>
            <h2 className='text-2xl sm:text-3xl lg:text-5xl font-bold mx-2.5 my-3 text-center'>Meet Our Team</h2>
            <div className='grid grid-rows-5 sm:grid-rows-3 grid-flow-col'>
               <TeamFeature
                    src={require('../../assets/team_img/ajith.jpg')}
                    desc="Ajith Senthil"
                    text=" CEO and AI/ML Team Lead."
                    height="h-[95px] md:h-[150px] lg:h-[200px]"
                    width="w-[94px] md:w-[150px] lg:w-[200px]"
                />
               <TeamFeature
                    src={require('../../assets/team_img/Fadaini Headshot.png')}
                    desc="Fadaini Micheal"
                    text="Fullstack Engineer"
                    height="h-[95px] md:h-[150px] lg:h-[200px]"
                    width="w-[94px] md:w-[150px] lg:w-[200px]"
                />
                <TeamFeature
                    src={require('../../assets/team_img/wei.jpg')}
                    desc="Wei Huang"
                    text="UI/UX Team Lead."
                    height="h-[95px] md:h-[150px] lg:h-[200px]"
                    width="w-[94px] md:w-[150px] lg:w-[200px]"
                />
               <TeamFeature
                    src={require('../../assets/team_img/borys.png')}
                    desc="Borys Jasinski"
                    text="Frontend Lead"
                    height="h-[95px] md:h-[150px] lg:h-[200px]"
                    width="w-[94px] md:w-[150px] lg:w-[200px]"
                />
                <TeamFeature
                    src={require('../../assets/team_img/Sarthak Headshot.png')}
                    desc="Sarthak Singh"
                    text="Backend and Cloud Engineer"
                    height="h-[95px] md:h-[150px] lg:h-[200px]"
                    width="w-[94px] md:w-[150px] lg:w-[200px]"
                />
                <TeamFeature
                   src={require('../../assets/team_img/profile-pic.png')}
                   desc="Sarthak Jain"
                   text="Backend and AI Engineer"
                   height="h-[95px] md:h-[150px] lg:h-[200px]"
                   width="w-[94px] md:w-[150px] lg:w-[200px]"
                />
                <TeamFeature
                    src={require('../../assets/team_img/IMG_20240327_173705.jpg')}
                    desc="Antony Njenga"
                    text="Frontend Developer"
                    height="h-[95px] md:h-[150px] lg:h-[200px]"
                    width="w-[94px] md:w-[150px] lg:w-[200px]"
                />
                <TeamFeature

                    src={require('../../assets/team_img/DSCF1302.jpg')}
                    desc="Nynika Badam"
                    text="UI/UX Developer"
                    height="h-[95px] md:h-[150px] lg:h-[200px]"
                    width="w-[94px] md:w-[150px] lg:w-[200px]"
                />
                <TeamFeature
                    src={require('../../assets/team_img/photo_of_myself.jpg')}
                    desc="William Liu"
                    text="AI Development Intern"
                    height="h-[95px] md:h-[150px] lg:h-[200px]"
                    width="w-[94px] md:w-[150px] lg:w-[200px]"
                />

            </div>
        </div>
    )
}

export default Team
