import React, { useState } from 'react'
import GraphNetwork from '../../DebatesPsychometrics/GraphNetwork'
import { debatesPsychometrics } from '../../../../mockups/Dashboard/debates'
import PsychBigger from '../../../Psych/PsychBigger'

const DebateGraphs = () => {

  const [clicked, setClicked] = useState(false)

  function viewBetter(){
    setClicked(!clicked)
  }

  return (
    <section className='w-full h-full'>
    <div className='my-4'>
     <p className='font-nunito text-base md:text-2xl italic text-center'>Graphs and Metrics</p>
    </div>

      <div 
      className='h-full w-full flex justify-center cursor-pointer'
      onClick={viewBetter}
      title='Tap to view enlarge graph'
      >
      <GraphNetwork 
        data={debatesPsychometrics}
        fullscreen={clicked}
        />
      </div>



      {clicked && 
    <PsychBigger 
    handleClosePopUp={viewBetter}
    child={
      <GraphNetwork 
        newBg="#4b5563"
        data={debatesPsychometrics}
        fullscreen={clicked}
        />
    }
    />
    }
    </section>
  )
}

export default DebateGraphs