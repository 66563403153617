import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { initializeWeb3Auth, loginWeb3Auth } from "../../store/auth/thunk";

const Form = () => {
  const dispatch = useDispatch();
  const { token, isLoading } = useSelector((state) => state.persistedReducer.auth);

  const handleSubmit = async (e) => {
    e.preventDefault()
    dispatch(loginWeb3Auth())
  };




  useEffect(() => {
    dispatch(initializeWeb3Auth());
    // eslint-disable-next-line 
  }, [token]);







  return <div>
    {isLoading
      ?
      (
        <div className='flex item-center justify-center h-full w-full'>
          <div
            className="my-4 animate-spin rounded-full h-16 w-16
             border-t-4 border-white"></div>
        </div>
      )
      :
      (
        <form onSubmit={handleSubmit} className="mt-5 px-8 pb-5">
          <button
            type="submit"
            className=" text-white bg-[#868BC7] hover:bg-purple-800 
       focus:ring-4 focus:outline-none focus:ring-purple-300 
       font-medium rounded-lg w-full  px-5 py-2.5 text-center"
          >
            Sign In
          </button>
        </form>
      )
    }

  </div>
};

export default Form;
