import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import EnhancedSearchBar from './QueryComponent/EnhancedSearchBar';
import DatasetCard from './QueryComponent/DataSetCard';
import { addToDataSet } from '../../redux/Slices/datasetSlice';
import { IoMdArrowDropdown } from "react-icons/io";


const MarketPlaceQuery = () => {
  const dispatch = useDispatch()

  const [searchResults, setSearchResults] = useState([]);


  const handleSearch = (query) => {
    // Simulated search results
    setSearchResults([
      { id: 1, title: 'Health Insurance Data', description: 'Comprehensive health insurance data from 2020-2022', price: 199 },
      { id: 2, title: 'Tax Policy Impact', description: 'Analysis of tax policy changes on different income brackets', price: 299 },
      { id: 3, title: 'International Trade Statistics', description: 'Detailed import/export data for major economies', price: 249 },
    ]);

    // Backend connect later
    // console.log(query)
  };

  // FILTER DATA PURCHASED OR NOT FROM THE SEARCH RESULT
  const removeDataFromSet = (dataTitle) => {
    const filterItem = searchResults.filter(({title}) => {
      return title !== dataTitle
    })
    setSearchResults(filterItem)
  }

  const handlePurchase = (title) => {
    const newPurchase = {
      title,
      date: new Date().toLocaleDateString(),
      price: searchResults.find(result => result.title === title).price
    };
    
    dispatch(
      addToDataSet({
        newPurchase
      })
    )
    removeDataFromSet(title)

  };




  return (
    <div className="">
      <main className="container mx-auto mt-4 px-2.5 py-4 sm:p-4">
        <h1 className="font-bold mb-4 text-center">What data are you looking for?</h1>
        <EnhancedSearchBar onSearch={handleSearch} />
        {(searchResults && searchResults.length > 0)
         &&
         <div className="mt-8 px-2.5 sm:px-4 relative h-screen
         overflow-y-scroll scroll-bar-in-dark">
        <p className='sticky top-0 w-full bg-[#211919] sm:bg-[#5B5B5B]
        text-2xl sm:text-4xl text-center
        italic pb-4'>Search Results</p>

         <div>
         {searchResults && searchResults.map((dataset) => (
            <DatasetCard
            key={dataset.id}
             {...dataset}
             actionName="Purchase"
             notPurchased={true}
             onPurchase={handlePurchase}
             onNoInterest={removeDataFromSet}
              />
          ))}
         </div>
        
  {searchResults.length > 1
  &&
  <p className='pt-2 text-center font-semibold text-sm
   sticky bottom-0 w-full bg-[#211919] sm:bg-[#5B5B5B] '>
    Scroll down to see more
    <IoMdArrowDropdown className='text-xl mx-auto text-white' />
    </p>
    }

        </div>
         }
      </main>
    </div>
  );
};

export default MarketPlaceQuery;
