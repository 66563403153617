import React from "react";
import CarouselComponent from "../../components/common/Carousel";

const MediaBoard = () => {
  return (
    <div className="hidden lg:block">

      <div className="lg:ml-4 w-full sm:w-[95%] flex justify-center
       pl-3 sm:px-5 py-7 bg-[#284B63] rounded-r-md">
          <CarouselComponent />
      </div>
      {/* <div className=""></div> */}
    </div>
  );
};

export default MediaBoard;
