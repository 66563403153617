import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    currentAvatar : "",
    loading: false,
    error: null

}


export const avatarSlice = createSlice({
    name:"avatar",
    initialState,
    reducers:{
        changeAvatar: (state, action) => {
            state.currentAvatar = action.payload
        }
    }
})



export const avatarData = state => state.avatar.currentAvatar

export const {changeAvatar} = avatarSlice.actions

export default avatarSlice.reducer
