import {
    signupFailure,
    signupStart
  } from "./slice";
  import {
    toast
  } from "react-toastify";


  export const signup =
    (navigate, url, body) =>
    async (dispatch) => {
      toast.loading("Processing...");
      dispatch(signupStart());

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          toast.dismiss();
          throw new Error("Signup failed");
        }

        const data = await response.json();

        if (data && data?.data !== null) {
          toast.dismiss();
          navigate("/signin");
          toast.success(`${data?.message}`);
        } else {
          toast.dismiss();
          toast.error(`${data?.message}`);
        }
      } catch (error) {
        toast.dismiss();
        dispatch(signupFailure(error.message));
        toast.error(error.message);
      }
    };
