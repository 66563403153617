import React from 'react';
import { readableDate } from '../../common/set_date';
import RepliesSection from './replies_section';
import { AiOutlineCaretRight, AiFillCaretDown } from 'react-icons/ai';
import MoreOptions from './moreOptions';


export const Replies = ({ showHideReplies, index, arrayToMap, reaction, userHasReacted, 
                        author_ID, showMoreActions, edit_delete_div, deleteComment
                }) => {


    return (<div 
    className={`mx-6 mb-7 ${showHideReplies[index] ? `block` : `hidden`}`} >

        {arrayToMap.map((element, idx) => {
            const reply_date_object = readableDate(element.date_created)

            return (
                <div key={element?.eid}>
                {element?.deleted ?
                <div className='flex flex-wrap ml-[8%] sm:ml-[14%]'>
                <p className='text-sm font-semibold m-3'>This comment has been deleted</p> 
                </div>
                
                :
                <div
                 onClick={() => {    
                    if(edit_delete_div[idx]){ //For closing the moreOptions div
                        showMoreActions(idx)
                    }
                 }}
                 className='relative'>

                <RepliesSection size="20" key={index}
                username={element?.author?.username}
                img={element?.author?.user_image}
                comment_date={reply_date_object}
                comment_text={element.text}
                upvote_count={element.upvote_count}
                downvote_count={element.downvote_count}
                user_Has_Reacted={userHasReacted}
                reactAction={(e) => {
                    const innerCommentID = element.eid
                    //bind innerCommentID with reaction()
                    reaction(e,innerCommentID)
                }}
            />
                {!element?.deleted && (
                <div className=''>
                {element?.author?.id === author_ID &&
                <MoreOptions
                    id={element?.eid} 
                    show_more_actions={showMoreActions}
                    index={idx}
                    editDelete={edit_delete_div} 
                    delete_comment={deleteComment}
                />
            }
                </div>
            )}
            </div>
                }
                <hr className='border-dotted'/>
                </div>
            )

        })}


    </div>)
}


export const ShowReplies = ({index, repliesTotal, seeReplies, setArray}) => {
    return(
        <div className='ml-[18%] lg:ml-[10%] '>
        {repliesTotal > 0 &&
            <button
                onClick={() => {
                    seeReplies(index)
                }}
                className='cursor-pointer'
                >
                {repliesTotal > 1 ? "Replies" : "Reply"}
                ({repliesTotal})

                {setArray[index] ?
                    <AiFillCaretDown className="my-2 text-lg inline" />
                     :
                    <AiOutlineCaretRight className="my-2 text-lg inline" />}
            </button>
        }
    </div>
    )
}
