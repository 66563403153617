import jwtDecode from "jwt-decode";


export function hasTokenExpired(token) {
  if (token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decodedToken.exp < currentTime;
  }
}

