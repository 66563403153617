import {
    createSlice,
} from "@reduxjs/toolkit"


const initialState = {
    datasets: [],
    loading: false,
    error: null
}


export const dataSetSlice = createSlice({
    name: "datasets",
    initialState,
    reducers:{
        addToDataSet: (state, action) => {
            const { newPurchase } = action.payload
            state.datasets.push(newPurchase)
        }
    }
})


export const datasetCard = state => state.datasetReducer.datasets


export const {addToDataSet} = dataSetSlice.actions


export default dataSetSlice.reducer
