import React from 'react'
import UserProfile from '../components/Profile/UserProfile'
import Footer from '../layout/Footer'
import Summary from '../components/MarketPlace/Summary'


function MarketPlace() {


    return (
        <div>
             <div className='bg-greenOnDarkMode text-white py-5 px-2.5 sm:p-5 mt-[100px]'>
                <UserProfile />

                <div className='bg-nightModeDark px-2 py-5 sm:px-4 rounded-xl'>
                    <p className='text-center font-bold text-2xl my-3 mt05'>
                    Psychometric Analysis Summary </p>
                    <div className='px-2 sm:px-4'>
                    <Summary />
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MarketPlace
