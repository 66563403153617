import React from 'react'
import { X } from 'lucide-react';
import { useSelector } from 'react-redux';
import { getToken } from '../../store/auth/selectors';

function CompleteProfilePopUp({handleClosePopUp, onClick}) {

    const token = useSelector(getToken);

    let newUserText = `Hello! Welcome to OpenPolitica!`

    let text = `Hm... It seems that this is your first time here! To ensure that 
            you have the best experience with us, we would like you to take a 
            couple seconds to fill out your user profile.`

    if (token && token?.newUser === "false") {
        newUserText = `Complete your profile`
        text = `You have not completed your profile, please complete setting up your profile
                 to have the best experience with us. `
  }

  return (
    <div
    className='h-full w-full flex justify-center items-center bg-[#000000]
                text-black py-2.5 fixed top-0 left-0 z-[50] bg-opacity-75'
            >
    <div className='relative bg-[#FFFFFF] h-[90%] md:h-4/5 w-[90%] sm:w-4/5 flex
     justify-center items-center rounded-md'>
        <X onClick={handleClosePopUp} className='absolute right-4 top-3 cursor-pointer text-2xl' />

        <div className='text-center md:w-2/4 p-5 z-[52]'>
        <h3 className='font-bold text-2xl mb-7'>{newUserText}</h3>

            <p className='font-bold'>{text}</p>

            <div className='mt-8 flex justify-center'>
            <button
            className='block rounded-md py-2 px-3 md:px-6
            text-white bg-updatedPurple hover:bg-[#767cb9]'
            onClick={onClick}>GO TO PROFILE SETTINGS</button>
            </div>
        </div>

        <div className="hidden lg:block absolute bg-[#E9E9E9] h-[50%] w-[80px] rounded-t-3xl left-[15%] bottom-0">
        {/* the color palettes */}
        </div>
      
        <div className="hidden lg:block absolute bg-[#E9E9E9] h-[60%] w-[80px] rounded-t-3xl left-[25%] bottom-0">
        {/* the color palettes */}
        </div>


        <div className="absolute bg-[#E9E9E9] h-[25%] lg:h-[25%] w-[60px] md:w-[80px]
         rounded-t-3xl right-[40%] md:right-[15%] bottom-0">
        {/* the color palettes */}
        </div>
      
        <div className="absolute bg-[#E9E9E9] h-[80%] w-[60px] md:w-[80px]
         rounded-t-3xl right-[10%] md:right-[25%] bottom-0">
        {/* the color palettes */}
        </div>

    </div>
      

    </div>
  )
}

export default CompleteProfilePopUp
