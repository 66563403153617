import React from 'react'
import FactCheck from './FactCheck';
import Suggest from './Suggest';
import DebateGraphs from './DebateGraphs';
import { useState } from 'react';

const UpdateSummary = () => {
  
    const [activeTab, setActiveTab] = useState(1)
    const handleClickTab = (tabIndex) => {
      setActiveTab(tabIndex);
    };
  
    const getButtonStyles = (tabIndex) => {
      return activeTab === tabIndex
        ? 'bg-black-800 text-white' // Active button styles ( black background and white text)
        : 'bg-[#202020] text-white'; // Inactive button styles (gray background and white text)
    };
  
    return (
      <div className='min-h-screen  w-full lg:w-1/2 rounded-lg bg-black'>
        <div className='flex justify-between items-center gap-1 flex-row '>
          <button
            className={`${getButtonStyles(1)} px-4 py-2 w-1/3`} 
            onClick={() => handleClickTab(1)}
          >
            <span className='font-nunito text-[10px] md:text-sm md:font-bold'>Suggest</span>
          </button>
          <button
            className={`${getButtonStyles(2)} px-4 py-2 w-1/3`} 
            onClick={() => handleClickTab(2)}
          >
            <span className='font-nunito text-[10px] md:text-sm md:font-bold'>Fact Check</span>
          </button>
          <button
            className={`${getButtonStyles(3)} px-4 py-2  w-1/3`} 
            onClick={() => handleClickTab(3)}
          >
            <span className='font-nunito text-[10px] md:text-sm md:font-bold'>Graphs</span>
          </button>
        </div>
  
        <div className='py-5 px-3 mt-[10px] bg-black'>
          {activeTab === 1 && <div><Suggest/></div>}
          {activeTab === 2 && <div><FactCheck/></div>}
          {activeTab === 3 && <div><DebateGraphs/></div>}
        </div>
      </div>
    );
}

export default UpdateSummary;
