import { createSlice } from "@reduxjs/toolkit";
import {
    setLoadingAndError,
    setError
} from "../../components/common/slice_state";
import { getRandomUserDetails } from "../Thunks/randomUserThunk";


const initialState = {
    user : {},
    loading: false,
    error: null
}


const randomUserProfileSlice = createSlice({
    name:"randomUser",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
        
        .addCase(getRandomUserDetails.pending, setLoadingAndError)
        .addCase(getRandomUserDetails.fulfilled, (state, action) => {
                state.user = action.payload || {}
                state.loading = false
        })
        .addCase(getRandomUserDetails.rejected, setError)

    }
})

// export const {

// } = userSlice.actions

export default randomUserProfileSlice.reducer
