import React from 'react'
import Analysis1 from './UserSummary/Analysis1'
import Analysis2 from './UserSummary/Analysis2'
import SaleRequests from './UserSummary/SaleRequests'


function Summary() {
  return (
    <div className='flex flex-wrap'>

    <div className='w-full sm:w-3/4'>
    <Analysis1 />
    </div>

   <div className='w-full sm:w-1/4'>
   <Analysis2 />
   <SaleRequests />
   </div>

    </div>
  )
}

export default Summary
