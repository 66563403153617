import React from 'react'

const DatasetCard = ({ title, description, price, startDate,
    endDate, actionName ,onPurchase, onNoInterest, notPurchased }) => (
    <>
        <div className='bg-white p-4 py-3 sm:py-5 text-black rounded-md my-4'>
            <div className='flex justify-between'>
                <p className='font-bold md:text-2xl w-[70%] mr-3'>{title}</p>
                <p className='font-bold text-sm italic w-auto'>
                    <span className='md:text-2xl not-italic text-[16px]'>${price} </span> 
                    <br />
                    from 4/30{startDate}
                    <br />
                     to 4/30{endDate}</p>
            </div>
            
            <p className='my-2 italic font-semibold text-xs md:text-[16px]'>{description}</p>
            <div className='bg-[#353535] text-white p-2.5 md:p-4 text-justify rounded-md'>
                <p className='indent-3'>Based off your search, we recommend this dataset
                    for your purposes. Our PolicyWeb has carefully curated
                    this set of data through an accumulation of our most
                    frequented users. This ensures that both you and our
                    trusted partners are happy with the information we
                    are providing, Things you may see include, most relevant
                    discussion threads, data relevant to your needs, and graphs
                    that analyze the use of the website by our users.</p>
            </div>
            <div className='my-3 flex'>

               {/* Show this button if user has this data set  */}
            {!notPurchased &&
            <div className='sm:w-[20%]' >
                <p>Expires on 4/30</p>
            </div>
            }

                <div className={`flex justify-end w-[80%] space-x-2 ${notPurchased && "w-full"}`}>
                {/* Show this button if user does not have this data set yet */}
            {notPurchased &&
                <button
                onClick={() => onNoInterest(title)}
                className="bg-[#F66262] text-white px-2.5 sm:px-6 py-2 rounded
                 hover:bg-red-600 transition-colors"
            >
            Not Interested
            </button>
            } 
            <button
                onClick={() => onPurchase(title)}
                className="bg-[#7AA65F] text-white px-2.5 sm:px-6 py-2 rounded
                 hover:bg-green-600 transition-colors"
            >
                {actionName}
            </button>
                </div>
            </div>

        </div>

    </>

);

export default DatasetCard
