import React from 'react'
import { GoThumbsdown, GoThumbsup } from 'react-icons/go'
import { Link } from 'react-router-dom'

function EachDebateActivity({topic, author, author_image, comment_with_the_most_impression_owner, date, text, 
                            upvote_count, downvote_count, discussion_id, commentOwnerProfileImage }) {


  return (
        
    <div className='my-8 relative'>

<div className="absolute top-0 right-2 h-[40px] w-[40px] sm:h-[60px] sm:w-[60px]">
                    <img
                    src={author_image}
                    alt="Author's avatar"
                    className={`w-full h-full rounded-full`}
                    />
                </div>

    <div className='space-y-2'>
    <p className=''>{topic}</p>
    <p className='text-sm font-semibold'>Created by: {author}</p>
    </div>


    {text && 
    <div className='flex mt-10'>
            <div className="h-[40px] w-[40px] sm:h-[60px] sm:w-[60px]">
                    <img
                    src={commentOwnerProfileImage}
                    alt="Comment with most impression user avatar"
                    className={`w-full h-full rounded-full`}
                    />
                </div>

                <div className='ml-2'>
                <p className='text-[14px] font-bold'>{comment_with_the_most_impression_owner}</p>
                <p className='text-[10px]'>{date}</p>
                    <p className='my-2.5'>{text}</p>

                    <div className='flex text-xs'>
                    <div className='flex space-x-4'>
                                    <p>
                                        {upvote_count}
                                        <GoThumbsup className='mx-1 inline' />
                                    </p>
                                    <p>
                                        {downvote_count}
                                        <GoThumbsdown className='mx-1 inline' />
                                    </p>
                                </div>
                    </div>

            </div>


    </div>
            }

    
    <div className={`flex justify-end my-3  ${!text && "mt-10" }`}>
    <Link to={`/discussions/${discussion_id}`}>
        <button className='px-6 sm:px-10 py-2 bg-[#474B7D] hover:bg-[#444770] rounded-md 
        text-sm sm:text-[16px]'>Open Discussion</button>
    </Link>
    </div>
 
</div>
  )
}

export default EachDebateActivity
