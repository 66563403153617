import { createAsyncThunk  } from "@reduxjs/toolkit";
import { headers, headerConfiguration } from "../../store/auth/headerConfig";
import api from "../../store/api/api";




export const getRandomUserDetails = createAsyncThunk(
    "randomUser/getRandomUserDetails",
    async (payload, {dispatch, getState}) => {
        const headerConfig = headerConfiguration(getState, headers)
        try {
          const url = `${process.env.REACT_APP_SERVER_URL}/api/user/profile/${payload?.user_id}/`;
          const response = await api(url, "GET", headerConfig, null, dispatch);
          return response
        } catch (error) {
          // console.error(error)
          return (error?.message);
        }
    }
)