export const cardDetails = [
    {
        cardType : "Visa ending in 7865",
        expires: "Expiry 07/26",
        isDefault: true
    },
    {
        cardType : "Mastercard ending in 6527",
        expires: "Expiry 07/26"
    },

]
