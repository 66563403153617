import React from 'react';
import { GoThumbsup, GoThumbsdown } from "react-icons/go"
import ChatPrompt from '../../ChatBox/ChatPrompt';
import IconButton from '../../common/iconButton';
import MoreOptions from './moreOptions';

const CommentImpression = ({ index, id, commentPoster, author_ID, username, comment_date, comment_text,
    upvote_count, downvote_count, replyComment, input_value, set_Input_Value, img,
    handleReplyChange, reply, replyPromptShow, edit_box,
    toggleReplyBox, edit_delete_div, showMoreActions,deleted,
    deleteComment, editComment, handleUpdate, cancelAction, reaction, userHasReacted }) => {

        

    return (
        <div 
        className='flex flex-wrap mx-2 relative my-3'
        onClick={() => {    
            if(edit_delete_div[index]){ //For closing the moreOptions div
                showMoreActions(index)
            }
            if(replyPromptShow[index]){ //For closing the reply input
                toggleReplyBox(index)
            }
             }}>
        {deleted ?
        <p className='text-sm font-semibold m-3'>This comment has been deleted</p> 
        :
        (        <div className='flex px-3'>
            <div className='w-[50px] md:pt-4'>
                <img 
                src={img} 
                alt={`${username} profile`}
                className='w-full h-[50px] rounded-full'
                />
            </div>

            <div className='px-2.5 lg:p-4 w-[90%] ml-2'>
                <p className='md:inline ml-0'>{username}</p>
                <p className='inline m-1 my-2.5 sm:m-2.5 text-xs md:text-sm'>{comment_date}</p>
                {edit_box[index] ?
                    (<div className='sm:w-[70%] relative'>
                        <input
                            className='pr-[35%] sm:pr-[25%] w-full bg-transparent rounded
                            border-b border-gray-500 focus:outline-none'
                            type="text"
                            value={input_value}
                            onChange={set_Input_Value}
                            autoFocus
                        />
                        <div className='absolute right-0 top-0'>
                            <IconButton
                                confirmClick={() => {
                                    handleUpdate(id, index)
                                }}
                                cancelEditing={cancelAction}
                                index={index}
                                backgroundColor="bg-gray-400"
                            />
                        </div>
                    </div>)
                    :
                   ( <p className='my-3'>
                        {comment_text}
                    </p>)

                }


                <div className='flex w-full my-3 space-x-6'>
                    <p>{upvote_count}
                        <GoThumbsup
                            onClick={(e) => {
                                reaction(e)
                            }}
                            id="Thumbs_Up"
                            className={`
                            mx-1 text-2xl hover:text-4xl inline cursor-pointer
                             ${( (userHasReacted && userHasReacted[0]?.vote_type === "D") && upvote_count > 0)
                             ? "text-[#3C6E71]" : null}
                             `}
                        />
                    </p>



                    <p>{downvote_count}

                        <GoThumbsdown
                            onClick={(e) => {
                                reaction(e)
                            }}
                            id="Thumbs_Down"
                            className={`mx-1 text-2xl hover:text-4xl inline cursor-pointer
                               ${( (userHasReacted && userHasReacted[0]?.vote_type === "U") && downvote_count > 0)
                             ? "text-[#3C6E71]" : null}
                             `}
                        />
                    </p>

                    <button
                        onClick={() => {
                            toggleReplyBox(index)
                        }}
                        className='cursor-pointer'>
                        Reply
                    </button>
                </div>

                {/* REPLY BOX TO TOGGLE: DESKTOP AND TABLET */}
                <div className='hidden md:block'>
                    {replyPromptShow[index] &&
                        <ChatPrompt
                            currentMode={true}
                            bgOnDark="bg-nightModeDark"
                            position="static"
                            placeholder="Post your reply"
                            submitPrompt={e => {
                                e.preventDefault()
                                replyComment(id, index)
                            }}
                            value={reply}
                            handleChange={handleReplyChange}
                        />}
                </div>


            </div>

            </div>)
        }

            {/* REPLY BOX TO TOGGLE: MOBILE ONLY*/}
            <div className='block md:hidden w-full'>
                {replyPromptShow[index] &&
                    <ChatPrompt
                        currentMode={true}
                        bgOnDark="bg-nightModeDark"
                        position="static"
                        placeholder="Post your reply"
                        submitPrompt={e => {
                            e.preventDefault()
                            replyComment(id, index)
                        }}
                        value={reply}
                        handleChange={handleReplyChange}
                    />}
            </div>


            {/* SHOW/HIDE DELETE OR EDIT DIV */}
            {!deleted && (
                <>
                {commentPoster === author_ID &&
                <MoreOptions
                    id={id} 
                    show_more_actions={showMoreActions}
                    index={index}
                    editDelete={edit_delete_div} 
                    edit_comment={editComment}
                    delete_comment={deleteComment}
                />
            }
                </>
            )}




        </div>
    );
}

export default CommentImpression;
