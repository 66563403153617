const stylesheet = [
    {
      selector: 'node',
      style: {
        'background-color': '#38bdf8',
        'label': 'data(user)',
        'color': '#ffffff',
        'text-outline-color': '#525252',
        'text-outline-width': 2,
        'font-size': 5,
        'text-valign': 'center',
        'text-halign': 'center',
        'text-wrap': 'wrap',
        'text-max-width': '20px',
        'border-color': '#0284c7', 
        'border-width': 3,
        'border-opacity': 0.8,
        'shape': 'ellipse',
      }
    },
    {
      selector: 'edge',
      style: {
        'width': 2,
        'font-size': 7,
        'label': 'data(type)',
        'color': '#ffffff',
        'text-outline-color': '#525252',
        'text-outline-width': 2,
        'line-color': '#aa7832',
        'target-arrow-color': '#aa7832',
        'target-arrow-shape': 'triangle',
        'curve-style': 'bezier',
      }
    },
    {
      selector: '.connected_nodes',
      style: {
        'background-color': '#d4d4d4',
        'border-color': '#525252',
      }
    },
    {
      selector: 'edge[type = "opposes"]',
      style: {
        'line-color': '#dc2626',
        'target-arrow-color': '#b91c1c'
      }
    },
    {
      selector: 'edge[type = "supports"]',
      style: {
          'line-color': '#16a34a', 
          'target-arrow-color': '#15803d'
        }

    },
    {
      selector: 'edge[type = "counters"]',
      style: {
           'line-color': '#1e40af', 
          'target-arrow-color': '#1e3a8a'
      }
    }
  ];
  
  const layout = {
    name: 'concentric',
  concentric: function(node) {
    // Return value determines which concentric level the node belongs to
    // You can customize based on node data
    return node.degree();
  },
  levelWidth: function() {
    // Return 8 for octagon shape in outer levels
    return 7;
  },
  minNodeSpacing: 30,
  padding: 30,
  idealEdgeLength: 100,
  nodeOverlap: 20,
  refresh: 20,
  fit: true,
  randomize: false,
  componentSpacing: 100,
  nodeRepulsion: 400000,
  edgeElasticity: 100,
  nestingFactor: 5,
  gravity: 80,
  numIter: 1000,
  initialTemp: 200,
  coolingFactor: 0.95,
  minTemp: 1.0
  };

export {stylesheet, layout}
