import { NavLink } from "react-router-dom";

const MenuItem = ({ link, title, activeLink, onClick }) => (
  <li
    onClick={onClick}
    className={`hover:text-purple-500 mx-2 font-nunito sm:text-lg font-bold`}
  >
    <NavLink to={link} className={`${activeLink === title ? "underline" : ""}`}>
      {title}
    </NavLink>
  </li>
);


export default MenuItem;