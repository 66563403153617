import { createAsyncThunk } from "@reduxjs/toolkit";
import { headers, headerConfiguration } from "../../store/auth/headerConfig";
import api from "../../store/api/api";



export const getTop5States = createAsyncThunk(
    "dashboard/getTop5States",
    async (_,{dispatch, getState}) => {
    
        const headerConfig = headerConfiguration(getState, headers)
        try {
          const url = `${process.env.REACT_APP_SERVER_URL}/api/dashboard/top-states/`;
          const response = await api(url, "GET", headerConfig, null, dispatch);
          return response
        } catch (error) {
          // console.error(error)
          return (error?.message);
        }
    }
) 


export const generalUserNeedPsychometrics = createAsyncThunk(
  'dashboard/generalUserNeedPsychometrics',
  async(_,{dispatch, getState}) => {

    const headerConfig = headerConfiguration(getState, headers)

    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/api/dashboard/userneeds/psychometrics/general/`;
      const response = await api(url, "GET", headerConfig, null, dispatch);
      return response
    } catch (error) {
      // console.error(error)
      return (error?.message);
    }
  }
)

