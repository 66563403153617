import React from 'react'
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { ChatIconBot } from '../common/svg-icons/svgs';
import { PiCardsFill } from "react-icons/pi";
import Walkthrough from '../About/Walkthrough'

function Description() {
  return (
    <div className='relative flex justify-center items-center p-4 rounded-md flex-wrap text-center bg-[#211E1E] m-2 md:m-5 mb-6  h-full'>

      <section className='flex justify-center items-center flex-wrap w-full' id="policyweb">
        <h2 className='text-2xl sm:text-3xl lg:text-5xl font-bold mx-2.5 my-3'>What is PolicyWeb?</h2>

        <p className='flex items-center justify-center  indent-8 text-[16px]  lg:w-5/6 sm:text-2xl m-4'>
          PolicyWeb, our innovative platform, is where technology meets democracy. It's a space where a chatbot listens to your concerns,
          analyzes your conversation using advanced Natural Language Processing, and creates a personalized  survey based on your specific  discussion.
        </p>

      </section>
      
      <div className='flex justify-center items-center flex-wrap p-4  w-full'>

      <div className='grid grid-rows-1 lg:grid-rows-1 grid-flow-row lg:grid-flow-col gap-4 mr-2'>
        {/* BOX 1 */}
        <div className='bg-greenOnDarkMode sm:bg-[#3C6E71] rounded-md
                        flex flex-wrap w-full sm:w-[450px] lg:w-[300px] m-4 p-2.5 sm:space-y-4 lg:text-center'>
            <div className={`my-auto w-[20%] lg:w-full`}>
            <BsFillChatLeftTextFill className='text-5xl sm:text-5xl border-none md:text-center shadow-2xl
                                                     mx-auto lg:mb-2'/>
            </div>
            <div className='w-[80%] lg:w-full text-left px-2 lg:text-center lg:-translate-y-1/4'>
            <p className='md:text-2xl font-bold my-1 mb-3'>Custom Surveys</p>
            <p className='inter-justify md:whitespace-normal text-sm    md:text-[18px] '>Each chat generates a unique survey, tailoring questions to your
            specific conversation.</p>
            </div>
        </div>

                {/* BOX 2 */}
          <div className='bg-greenOnDarkMode sm:bg-[#3C6E71]  rounded-md flex flex-wrap items-center justify-center w-full sm:w-[450px] lg:w-[300px] lg:text-center m-4 p-2.5 md:space-y-4'>

            <div className='my-auto w-[20%] lg:w-full'>
              <ChatIconBot className='text-5xl md:text-xl border-none shadow-2xl
                                                     mx-auto'/>
            </div>
            <div className='w-[80%] lg:w-full text-left px-2 lg:text-center lg:-translate-y-1/4'>
            <p className='md:text-2xl font-bold my-1 mb-3'>Natural Language Analysis</p>
            <p className='inter-justify  text-sm   md:text-[18px]'>Our AI chatbot delves deep into your conversation,
            extracting key outcomes and concerns.</p>
            </div>
         </div> 



                {/* BOX 3 */}
        <div className='bg-greenOnDarkMode sm:bg-[#3C6E71] rounded-md
                        flex flex-wrap w-full  sm:w-[450px] lg:w-[300px] m-4 p-2.5 sm:space-y-4 lg:text-center'>
            <div className={`my-auto w-[20%] lg:w-full`}>
            <PiCardsFill className='text-5xl sm:text-6xl border-none shadow-2xl
                                                     mx-auto'/>
            </div>
            <div className='w-[80%] lg:w-full text-left px-2 lg:text-center'>
            <p className='md:text-2xl font-bold my-1 mb-3'>Policy Cards</p>
            <p className='inter-justify md:whitespace-normal   text-sm md:text-[18px]'>These are the heart of PolicyWeb, enabling robust discussions in
            forums and showcasing relevant data visualizations, all within a user-friendly
            dashboard.</p>
            </div>
        </div>
      </div>
      </div>

      
      <Walkthrough/>

    </div>
  )
}

export default Description
