import React, { useEffect, useState } from 'react'
import Interests from '../components/FeedBack/Interests'
import Partnership from '../components/FeedBack/Partnership'

function FeedBack() {
    const [pageToShow, setPageToShow] = useState(1)

    const handleOnUserSubmit = (num) => {
        setPageToShow(num)
    }

  let screen = <Interests feedbackToDisplay={handleOnUserSubmit} />
  if(pageToShow === 2 ){
    screen = <Partnership feedbackToDisplay={handleOnUserSubmit} />
  }

  useEffect(() => {
    // console.log(pageToShow)
  },[pageToShow])

  return (
    <div>
        {screen}
    </div>
  )
}

export default FeedBack
