export const showPsychometricDataToUser = (event, cy, previousTapTarget, setFeedBack) => {

    setFeedBack("")
    // Clear previous styles
    if (previousTapTarget) {
        previousTapTarget.style('label', ''); // Clear the label
        previousTapTarget.style({ // Reset to default
            'line-color': '',
            'width': '',
            'color': '',
            'text-background-color': '',
            'text-background-shape': '',
            'text-background-padding': '',
            'font-family': '',
            'font-size': '',
            'text-margin-y': ''
        });


        // If it was an edge, reset the connected nodes
        if (previousTapTarget.isEdge() ) {
            cy.getElementById(previousTapTarget.data('source')).style('background-color', '');
            cy.getElementById(previousTapTarget.data('target')).style('background-color', '');
        }

        
        // If it was a node, reset it
        if (previousTapTarget.isNode() ) {
            cy.getElementById(previousTapTarget.data('id')).style({
                'background-color': '#d4d4d4',
                'border-color': '#525252',
                'text-outline-color': '#525252',
                'text-outline-width': 2,
                'text-valign': 'center',
                'text-halign': 'center',
                'text-wrap': 'wrap',
      })
        }
    }

    // If background is clicked, just clear and return
    if (event.target === cy) {
        previousTapTarget = null;
        return;
    }

    // Handle edge tap
    if (event.target.isEdge()) {
        // The data contained in the clicked edge 
        const edgeData = event.target.data();

        const sourceNode = cy.getElementById(edgeData?.source);
        const targetNode = cy.getElementById(edgeData .target);

        if (sourceNode.length > 0 && targetNode.length > 0) {
            // Get the source node data
            const sourceNodeData = sourceNode.data();
            // Get the target node data
            const targetNodeData = targetNode.data();

            sourceNode.style({
                'background-color': '#38bdf8',
            });
            targetNode.style({
                'background-color': '#38bdf8',
            });

            event.target.style({
                "label": `${targetNodeData?.user} claimed that ${targetNodeData?.claim}\n\nEVIDENCE: ${targetNodeData?.evidence}\n\n${sourceNodeData?.user} ${edgeData?.type}\nCLAIM: ${sourceNodeData?.claim}\n\n EVIDENCE: ${sourceNodeData?.evidence}`,
                "text-wrap": "wrap",
                "text-max-width": "180px", 
                "text-background-color": "#000000",
                "text-background-opacity": 1,
                "text-background-shape": "round-rectangle",
                "text-background-padding": "10px",
                "font-size": "6px",
                "color": "#ffffff",
                "text-valign": "top",
                "text-events": "yes",
                "z-index": 9999,
                'text-outline-width': 0,
            });

            previousTapTarget = event.target;
            return previousTapTarget
        } else {
            setFeedBack("Source or target node not found");
        }
    }


    
    // Handle Node Tap
    if (event.target.isNode()) {
      // The data contained in the clicked edge 
      const nodeData = event.target.data();

      const node = cy.getElementById(nodeData?.id);
      node.style({
                'background-color': '#a855f7',
                'border-color': '#7e22ce', 
                'text-outline-color': '#581c87',
            });

            event.target.style({
                "label": `${nodeData?.user} claimed that ${nodeData?.claim}\n\nEVIDENCE: ${nodeData?.evidence}`,
                "text-max-width": "180px", 
                "text-background-color": "#000000",
                "text-background-opacity": 1,
                "text-background-shape": "round-rectangle",
                "text-background-padding": "10px",
                "font-size": "6px",
                "color": "#ffffff",
                "text-valign": "top",
                "text-events": "yes",
                "z-index": 9999,
                'text-outline-width': 0,
            });
      
            previousTapTarget = event.target;
            return previousTapTarget;
    }else{
            setFeedBack("Node not found");
        }
}