export const debates = [{
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "This definitely needed a little more work before it came out.",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "post_title": "On April 23rd, the Supreme Court made a decision to repeal Mike v. Gerald. What are the pros and cons of this decision?",
        "author": "Xing Madi",
        "parent": null,
        "username": "Mike Mike"
    },
    {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "This definitely needed a little more work before it came out.",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "post_title": "The new discussion for raising taxes is a terrible idea. What are your thoughts?",
        "author": "Xing Madi",
        "parent": null,
        "username": "Mike Mike"
    },
    {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "This definitely needed a little more work before it came out.",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "post_title": "The new discussion for raising taxes is a terrible idea. What are your thoughts?",
        "author": "Xing Madi",
        "parent": null,
        "username": "Mike Mike"
    },
    {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "This definitely needed a little more work before it came out.",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "post_title": "On April 23rd, the Supreme Court made a decision to repeal Mike v. Gerald. What are the pros and cons of this decision?",
        "author": "Xing Madi",
        "parent": null,
        "username": "Mike Mike"
    },
    {
        "eid": "089e610c-73b6-4e68-a955-9c065e856baa",
        "date_created": "2024-05-20T19:11:55.532634Z",
        "upvote_count": 1,
        "downvote_count": 0,
        "text": "This definitely needed a little more work before it came out.",
        "deleted": false,
        "post": "ee291ce0-dabc-4cd1-9455-935b016d8d07",
        "post_title": "On April 23rd, the Supreme Court made a decision to repeal Mike v. Gerald. What are the pros and cons of this decision?",
        "author": "Xing Madi",
        "parent": null,
        "username": "Mike Mike"
    },
]


export const debatesPsychometrics = {
    nodes: [{
        'data': {
            'id': 'n1',
            'user': 'Alice',
            'claim': 'Implementing a universal basic income (UBI) would significantly reduce poverty.',
            'evidence': 'Providing a basic income to all individuals, regardless of their employment status, would ensure that everyone has a minimum level of income to meet their basic needs.'
        }
    }, {
        'data': {
            'id': 'n2',
            'user': 'Bob',
            'claim': 'Implementing a universal basic income (UBI) is too expensive and would discourage people from working.',
            'evidence': 'UBI would be costly and would lead to people relying on the government for financial support instead of working for their own income.'
        }
    }, {
        'data': {
            'id': 'n3',
            'user': 'Carol',
            'claim': 'Implementing a universal basic income (UBI) can stimulate economic growth.',
            'evidence': 'Studies have shown that UBI can increase consumer spending.'
        }
    }, {
        'data': {
            'id': 'n4',
            'user': 'Dave',
            'claim': 'Implementing a universal basic income (UBI) would hurt businesses and the economy due to higher taxes.',
            'evidence': 'Higher taxes would decrease the profits of businesses, making it difficult for them to operate and contribute to the economy. This would also discourage businesses from investing and creating jobs, further impacting the economy.'
        }
    }, {
        'data': {
            'id': 'n5',
            'user': 'Eve',
            'claim': 'Existing welfare funds could be reallocated to fund UBI, resulting in a reduction of administrative costs.',
            'evidence': 'By implementing UBI, there would be a decrease in administrative costs because there would no longer be a need for multiple welfare programs. This would result in more funds being available for UBI.'
        }
    }, {
        'data': {
            'id': 'n6',
            'user': 'Frank',
            'claim': 'Reallocating funds is not enough to support UBI.',
            'evidence': 'UBI requires a significant amount of funding, and simply reallocating existing welfare funds would not be enough to cover the costs.'
        }
    }, {
        'data': {
            'id': 'n7',
            'user': 'Alice',
            'claim': 'Implementing a progressive tax system where the wealthy contribute more could be a solution to funding UBI.',
            'evidence': 'This would generate new revenue sources, which is necessary for UBI to be implemented.'
        }
    }, {
        'data': {
            'id': 'n8',
            'user': 'Grace',
            'claim': 'Implementing a progressive tax system may lead to negative consequences such as capital flight and reduced investments.',
            'evidence': 'A progressive tax system may discourage wealthy individuals from investing in the economy, potentially leading to a decrease in overall economic growth and development.'
        }
    }, {
        'data': {
            'id': 'n9',
            'user': 'Bob',
            'claim': 'Giving money unconditionally may lead to misuse on non-essential items.',
            'evidence': 'Implementing a progressive tax system may lead to capital flight and reduced investments, which could potentially harm the economy.'
        }
    }, {
        'data': {
            'id': 'n10',
            'user': 'Helen',
            'claim': 'Research indicates that recipients of UBI spend money responsibly, particularly on education and health.',
            'evidence': 'Research has been conducted on the spending habits of UBI recipients, and the results show that they tend to use the money for essential items such as education and health.'
        }
    }, {
        'data': {
            'id': 'n11',
            'user': 'Ian',
            'claim': 'Providing income without requiring work creates a moral hazard and undermines the work ethic.',
            'evidence': 'By giving money unconditionally, individuals may become less motivated to work and may rely solely on the income provided. This can lead to a decrease in productivity and a lack of work ethic.'
        }
    }, {
        'data': {
            'id': 'n12',
            'user': 'Jane',
            'claim': 'UBI could enable people to pursue education and training, resulting in a more skilled workforce.',
            'evidence': 'UBI would provide individuals with the financial means to pursue education and training, which would ultimately lead to a more skilled workforce.'
        }
    }, {
        'data': {
            'id': 'n13',
            'user': 'Bob',
            'claim': 'There is a concern about who will perform low-skilled jobs if everyone is pursuing higher education due to the implementation of UBI.',
            'evidence': 'UBI could potentially lead to a more skilled workforce as people may use the income to pursue education and training. However, this could also result in a shortage of workers for low-skilled jobs.'
        }
    }, {
        'data': {
            'id': 'n14',
            'user': 'Frank',
            'claim': 'Automation is reducing the need for low-skilled labor and the economy needs to adapt to this change.',
            'evidence': 'With the advancement of technology and automation, many low-skilled jobs are becoming obsolete. This is leading to a shift in the job market and a need for a more skilled workforce.'
        }
    }, {
        'data': {
            'id': 'n15',
            'user': 'Carol',
            'claim': 'UBI can act as a safety net during the economic transition caused by automation.',
            'evidence': 'Automation is causing a shift in the job market, reducing the need for low-skilled labor.'
        }
    }],

    edges: [{
        'data': {
            'id': 'e2-1',
            'source': 'n2',
            'target': 'n1',
            'type': 'opposes'
        }
    }, {
        'data': {
            'id': 'e3-1',
            'source': 'n3',
            'target': 'n1',
            'type': 'supports'
        }
    }, {
        'data': {
            'id': 'e3-2',
            'source': 'n3',
            'target': 'n2',
            'type': 'opposes'
        }
    }, {
        'data': {
            'id': 'e4-3',
            'source': 'n4',
            'target': 'n3',
            'type': 'opposes'
        }
    }, {
        'data': {
            'id': 'e5-3',
            'source': 'n5',
            'target': 'n3',
            'type': 'supports'
        }
    }, {
        'data': {
            'id': 'e5-4',
            'source': 'n5',
            'target': 'n4',
            'type': 'counters'
        }
    }, {
        'data': {
            'id': 'e6-5',
            'source': 'n6',
            'target': 'n5',
            'type': 'opposes'
        }
    }, {
        'data': {
            'id': 'e7-6',
            'source': 'n7',
            'target': 'n6',
            'type': 'supports'
        }
    }, {
        'data': {
            'id': 'e7-4',
            'source': 'n7',
            'target': 'n4',
            'type': 'supports'
        }
    }, {
        'data': {
            'id': 'e8-7',
            'source': 'n8',
            'target': 'n7',
            'type': 'opposes'
        }
    }, {
        'data': {
            'id': 'e9-7',
            'source': 'n9',
            'target': 'n7',
            'type': 'opposes'
        }
    }, {
        'data': {
            'id': 'e9-8',
            'source': 'n9',
            'target': 'n8',
            'type': 'supports'
        }
    }, {
        'data': {
            'id': 'e10-9',
            'source': 'n10',
            'target': 'n9',
            'type': 'supports'
        }
    }, {
        'data': {
            'id': 'e11-10',
            'source': 'n11',
            'target': 'n10',
            'type': 'opposes'
        }
    }, {
        'data': {
            'id': 'e11-9',
            'source': 'n11',
            'target': 'n9',
            'type': 'supports'
        }
    }, {
        'data': {
            'id': 'e12-10',
            'source': 'n12',
            'target': 'n10',
            'type': 'supports'
        }
    }, {
        'data': {
            'id': 'e12-9',
            'source': 'n12',
            'target': 'n9',
            'type': 'supports'
        }
    }, {
        'data': {
            'id': 'e12-11',
            'source': 'n12',
            'target': 'n11',
            'type': 'opposes'
        }
    }, {
        'data': {
            'id': 'e13-12',
            'source': 'n13',
            'target': 'n12',
            'type': 'opposes'
        }
    }, {
        'data': {
            'id': 'e14-12',
            'source': 'n14',
            'target': 'n12',
            'type': 'supports'
        }
    }, {
        'data': {
            'id': 'e14-13',
            'source': 'n14',
            'target': 'n13',
            'type': 'supports'
        }
    }, {
        'data': {
            'id': 'e14-11',
            'source': 'n14',
            'target': 'n11',
            'type': 'opposes'
        }
    }, {
        'data': {
            'id': 'e15-14',
            'source': 'n15',
            'target': 'n14',
            'type': 'supports'
        }
    }]
}