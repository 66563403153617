import { createSlice } from "@reduxjs/toolkit"
import { createDebate, getAllDebates,
     getEachDebate, createDebateComment,
     getEachDebateComments, 
     editDebateComment,
     deleteDebateComments} from "../Thunks/debatesThunk"
import { setError, setLoadingAndError } from "../../components/common/slice_state"


const initialState = {
    allDebates : [],
    oneDebate : null,
    debatesComments : [],
    loading : false,
    error : null,
}


export const debateSlice = createSlice({
    name : "debates",
    initialState,
    reducers : {
        updateDebateComment: (state, action) => {
            const {eid, edited_comment } = action.payload

            const existingPolicy = state.debatesComments.find((element) => element.eid === eid)
            if (existingPolicy) {
                // Update the properties with the new values
                existingPolicy.text = edited_comment;
            }

        }
    },
    extraReducers: (builder) => {
        builder

        .addCase(createDebate.pending, setLoadingAndError)
        .addCase(createDebate.fulfilled, (state, action) => {
             state.loading = false
             state.error = null
        })
        .addCase(createDebate.rejected, setError)



        .addCase(getAllDebates.pending, setLoadingAndError)
        .addCase(getAllDebates.fulfilled, (state, action) => {
             state.loading = false
             state.error = null
             state.allDebates = action.payload || []
        })
        .addCase(getAllDebates.rejected, setError)



        .addCase(getEachDebate.pending, setLoadingAndError)
        .addCase(getEachDebate.fulfilled, (state, action) => {
             state.loading = false
             state.error = null
             state.oneDebate = action.payload || null
        })
        .addCase(getEachDebate.rejected, setError)



        .addCase(createDebateComment.pending, setLoadingAndError)
        .addCase(createDebateComment.fulfilled, (state) => {
             state.loading = false
             state.error = null
        })
        .addCase(createDebateComment.rejected, setError)



        .addCase(getEachDebateComments.pending, setLoadingAndError)
        .addCase(getEachDebateComments.fulfilled, (state, action) => {
             state.loading = false
             state.error = null
             state.debatesComments = action.payload || []
        })
        .addCase(getEachDebateComments.rejected, setError)



        .addCase(editDebateComment.pending, setLoadingAndError)
        .addCase(editDebateComment.fulfilled, (state) => {
             state.loading = false
             state.error = null
        })
        .addCase(editDebateComment.rejected, setError)



        .addCase(deleteDebateComments.pending, setLoadingAndError)
        .addCase(deleteDebateComments.fulfilled, (state) => {
             state.loading = false
             state.error = null
        })
        .addCase(deleteDebateComments.rejected, setError)



        
    }
})


export const {updateDebateComment} = debateSlice.actions


export default debateSlice.reducer