import React, { useEffect} from 'react'
import DebateChat from '../../DebateChat'
import { useDispatch, useSelector } from 'react-redux'
import { getAllDebates } from '../../../../redux/Thunks/debatesThunk'

const ChatRoom = () => {
  const dispatch = useDispatch()
  const debaters = useSelector(state => state.debates.allDebates);

  const stance = ['opposing ' || 'supporting']

  useEffect(() => {
    dispatch(
      getAllDebates()
    )
  }, [dispatch])

  return (
    <>
    {debaters.length > 0 && 
    (

      <div className='p-1 md:p-10 relative h-[80vh] md:min-h-[50vh] mb-10 overflow-y-scroll scroll-bar-in-dark'>

            <DebateChat
              name={debaters[0]?.supporting[0]?.name}
              stance='supporting'
              time={debaters[0]?.duration}
              containerClass='w-1/2'
            />

            <DebateChat
              name={debaters[0]?.supporting[0]?.name}
              stance='opposing'
              time={debaters[0]?.duration}
              containerClass={stance === 'opposing' ? 'float-right' : ''}
            />
        <div className='w-full flex justify-center text-center my-4'>
          <p className='font-nunito text-sm sm:text-xl italic'>PolicyWeb: Are there any more points?</p>
        </div>

            <DebateChat
              name={debaters[0]?.supporting[0]?.name}
              stance='supporting'
              time={debaters[0]?.duration}
              containerClass='w-1/2'
            />

            <DebateChat
              name={debaters[0]?.supporting[0]?.name}
              stance='opposing'
              time={debaters[0]?.duration}
              containerClass={stance === 'opposing' ? 'float-right' : ''}
            />
      </div>
    )}
    </>
  );
};

export default ChatRoom;


