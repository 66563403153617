import React from 'react'
import DebateChat from '../../DebateChat'
import TextArea from './TextArea';

const FactCheck = () => {
  return (
    <section className='p-1 md:p-4'>
    <div className=''>
    <div>
      <h1 className='italic font-nunito text-base md:text-2xl'>Fact Check</h1>
    </div>

    <div className='h-[50vh] overflow-y-scroll scroll-bar-in-dark'>
       <div className='flex flex-col p-3'>
          <DebateChat
            name='Correct!'
            stance='Input shown hereInput shown here Input shown here Input shown here Input shown hereInput shown here Input shown here'
            time='05:03:27PM CST'
            containerClass='w-3/4'
          />
       
       </div>

       <div className='flex flex-col p-3'>
          <DebateChat
            name='Incorrect!'
            stance='Input shown hereInput shown here Input shown here Input shown here Input shown hereInput shown here Input shown here'
            time='05:07:31PM CST'
            containerClass='w-3/4'
          />

            

       </div>

    </div>

    <TextArea/>
    </div>
    </section>
  )
}

export default FactCheck