import React from 'react'
import { X } from 'lucide-react';

function PsychBigger({ handleClosePopUp, child }) {
    return (
        <div className='h-full w-full flex justify-center items-center bg-white
    text-black p-6 fixed top-0 left-0 z-[1000] bg-opacity-75'>
                        <style>
                            {`
            @keyframes rotate {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
            }
            `}
            </style>


            <X onClick={handleClosePopUp}
                className='absolute right-0 sm:right-3 top-0 sm:top-3 cursor-pointer text-3xl z-[1]'
                size={34}
            />

            <div>
            {child}
            </div>
            
            </div>
    )
}

export default PsychBigger