import { createAsyncThunk } from "@reduxjs/toolkit";
import { headerConfiguration, headers } from "../../store/auth/headerConfig";
import api from "../../store/api/api";
import { toast } from "react-toastify";


export const createDiscussion = createAsyncThunk(
    "discussions/createDiscussion",
    async (payload, {dispatch, getState}) => {
        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/api/discussions/`
            
            // Create FormData object
            const formData = new FormData();
            formData.append('topic', payload.topic);
            formData.append('description', payload.description);
            formData.append('author', payload.author);
            
            // Append each media file
            if (payload.media && payload.media.length > 0) {
                payload.media.forEach((file) => {
                    formData.append('media', file); 
                });
            }
            
  
            const headerConfig = headerConfiguration(getState, headers, true);
            delete headerConfig['Content-Type']; // Important: remove Content-Type header
            
            toast.loading("Creating discussion...")
            const response = await api(url, "POST", headerConfig, formData, dispatch);
            toast.dismiss()
            toast.success(response?.message);
            return response;
        } catch (error) {
            console.error(error);
            toast.dismiss()
            toast.error(error.message || 'Failed to create discussion');
            return error;
        }
    }
)

export const getAllDIscussions = createAsyncThunk(
    "discussions/getAllDIscussions",
    async (_,{dispatch, getState}) => {
        try {
            
            const url = `${process.env.REACT_APP_SERVER_URL}/api/discussions/`
            const headerConfig = headerConfiguration(getState, headers)
            const response = await api(url, "GET", headerConfig, null, dispatch)
            return response

        } catch (error) {
            console.log(error)
            return error
        }
    }
)

export const getEachDiscussion = createAsyncThunk(
    "discussions/getEachDiscussion",
    async (payload,{dispatch, getState}) => {

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/api/discussions/${payload.discussion_id}/`
            const headerConfig = headerConfiguration(getState, headers)
            const response = await api(url, "GET", headerConfig, null, dispatch)
            return response

        } catch (error) {
            console.log(error)
            return error
        }
    }
)


export const createDiscussionComment = createAsyncThunk(
    "discussions/createDiscussionComment",
    async ({discussion_id, author, text, parent},{dispatch, getState}) => {

        const body ={
            discussion : discussion_id,
            author,
            text,
            parent
        }

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/api/discussions/comments/${discussion_id}/`
            const headerConfig = headerConfiguration(getState, headers)
            const response = await api(url, "POST", headerConfig, body, dispatch)
            toast.dismiss()
            toast.success(response?.message)
            return response;

        } catch (error) {
            console.error(error);
            toast.dismiss()
            toast.error(error?.message || 'Failed to create comment');
            return error;
        }
    }
)


export const getEachDiscussionComments = createAsyncThunk(
    "discussions/getEachDiscussionComments",
    async (payload,{dispatch, getState}) => {

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/api/discussions/comments/${payload.discussion_id}/`
            const headerConfig = headerConfiguration(getState, headers)
            const response = await api(url, "GET", headerConfig, null, dispatch)
            return response

        } catch (error) {
            console.log(error)
            return error
        }
    }
)

export const editDiscussionComment = createAsyncThunk(
    "discussions/editDiscussionComment",
    async (payload,{dispatch, getState}) => {

        const body ={
            text : payload.edited_comment
        }

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/api/discussions/comments/${payload?.eid}/`
            const headerConfig = headerConfiguration(getState, headers)
            const response = await api(url, "PATCH", headerConfig, body, dispatch)
            return response;

        } catch (error) {
            console.error(error);
            toast.dismiss()
            toast.error(error?.message || 'Failed to edit comment');
            return error;
        }
    }
)


export const deleteDiscussionComments = createAsyncThunk(
    "discussions/deleteDiscussionComments",
    async (payload,{dispatch, getState}) => {

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/api/discussions/comments/${payload?.eid}/`
            const headerConfig = headerConfiguration(getState, headers)
            const response = await api(url, "DELETE", headerConfig, null, dispatch)
            return response

        } catch (error) {
            console.log(error)
            return error
        }
    }
)