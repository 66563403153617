import React, { useEffect, useState } from 'react'
import { PasswordResetInput, UnRequiredInput } from '../common/input'
import { FaPlus } from 'react-icons/fa6';
import { ButtonChoose } from '../common/iconButton';
import { useDispatch, useSelector } from 'react-redux';
import { authTokens, userInfo } from '../../redux/Selectors/selectors';
import { createDiscussion } from '../../redux/Thunks/discussionsThunk';
import DebateDuration from './DebateDuration';
import { makeApiRequest } from '../common/API_request';
import SearchBoxResult from './SearchBoxResult';
import FinalDebaters from './FinalDebaters';
import UploadedImagesDisplay from './UploadedImagesDisplay';
import { itemExists } from '../common/checks_Func';
import { createDebate } from '../../redux/Thunks/debatesThunk';
import DateTimePicker from './DateTimePicker';


function CreateForm({ category }) {

    const dispatch = useDispatch()
    const user = useSelector(userInfo)
    const tokens = useSelector(authTokens)

    const userDebateData = user && {
        id : user.id,
        name: user.name,
        profileImage : user.profileImage
    }

    const [errorMsg, setErrorMsg] = useState("")
    const [supportingErrorMessage, setSupportingErrorMessage] = useState("")
    const [opposingErrorMessage, setOpposingErrorMessage] = useState("")


    const [inputValue, setInputValue] = useState({
        topic: "",
        description: "",
        duration : 30,
        author: user && user.id,
        date : "",
        time: '',
        timezone: "UTC"
    })
    


    const [supportsTopic, setSupportsTopic] = useState(user ? [userDebateData] : [])
    const [opposesTopic, setOpposesTopic] = useState([])
    const [searchedResult, setSearchedResult] = useState({
        supporters: [],
        opposers: []
    })
    const [media, setMedia] = useState([])
    const [videoYes, setVideoYes] = useState(false)
    const [debater, setDebater] = useState(true)
    const [closeSearchResult, setCloseSearchResult] = useState(false)

    function closeSearchBox(){
        setCloseSearchResult(true)
    }

    function isValidFileType(file) {
        const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        const validTypes = videoYes ? [...validImageTypes, 'video/mp4'] : validImageTypes;
        return validTypes.includes(file.type);
    };

    function removeMedia(index) {
        setMedia(media.filter((_, idx) => {
            return idx !== index
        }))
    }



    const handleTextChange = (e, dataToExtract) => {

        const { name, value } = dataToExtract ? dataToExtract : e.target
        
        setErrorMsg("")
        setInputValue((prevValue) => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }


    const handleFIleInput = (e) => {

        setErrorMsg('');
        const maxFiles = 4
        const maxSizeInMB = 100
        const fileObjects = Array.from(e.target.files)

        // Check number of files
        if (fileObjects.length > maxFiles) {
            setErrorMsg(`Maximum number (${maxFiles}) of media uploads exceeded`)
            return;
        }

        // Validate each file
        const validatedFiles = []
        for (const file of fileObjects) {
            // Check file size
            if (file.size > maxSizeInMB * 1024 * 1024) {
                setErrorMsg(`${file.name} is too large (max ${maxSizeInMB}MB)`)
                return;
            }

            // Check file type
            if (!isValidFileType(file)) {
                const feedBack = videoYes
                    ? 'is neither a valid video nor a valid photo'
                    : 'is not a valid photo';
                setErrorMsg(`${file.name} ${feedBack}`);
                return;
            }

            validatedFiles.push(file);
        }


        if (media.length > 0) {
            setMedia((prevValue) => {
                return [...validatedFiles, ...prevValue]
            });
        } else {
            setMedia(validatedFiles)
        }

    };

    const searchUser = async (e, role) => {
        // This function searches for a user to participate in a debate, 
        // If the role is opposition, the user gets added to the setSearchedResult.opposers
        // if role is supporting, the user gets added to the setSearchedResult.supporters
        // NB: The input field using this function is uncontrolled
        setCloseSearchResult(false)
        const {value} = e.target
        if(value.length > 0){
                const url = `${process.env.REACT_APP_SERVER_URL}/api/debates/find_debaters/${value}`
                const headerConfig = {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${tokens?.accessToken}`
                }
                const response = await makeApiRequest(url,"GET", headerConfig, null)
        
                if(role === "opps"){
                    setSearchedResult({
                        opposers: response || [],
                        supporters: []
                    })
                }else{
                    setSearchedResult({
                        supporters: response || [],
                        opposers: []
                    })
                }

        }else{
            setSearchedResult({
                supporters: [],
                opposers: []
            })
        }


    }

    function selectDebater(item, role){
        // What this function does is, it selects debaters,
        // if the debater was clicked from the support searched result, they get added to supportsTopic
        //  else they get added to opposesTopic
        // The function also checks if the debater has been picked already to avoid duplication,
        // Also a user picked to support cannot oppose.  

        setSupportingErrorMessage("")
        setOpposingErrorMessage("")

        const existsInSupport = itemExists(supportsTopic, item)
        const existsInOppose = itemExists(opposesTopic, item)

        if(!existsInSupport && !existsInOppose && role === "supp"){
            setSupportsTopic((prev) => {
                return [...prev, item]
            })
        }else if(!existsInSupport && !existsInOppose && role === "opps"){
            setOpposesTopic((prev) => {
                return [...prev, item]
            })
        }else{
            if(existsInSupport && role === "opps"){
                setSupportingErrorMessage(`${item?.name} is on the supporting side`)
            }else if(existsInOppose && role === "supp"){
                setOpposingErrorMessage(`${item?.name} is on the supporting side`)
            }
        }

    }

    function removeDebater(id, role){
        // This function removes a selected debater depending 
        // if they were supporting or opposing
        if(role === "supp"){
            setSupportsTopic(
                supportsTopic.filter((item) => {
                    return item.id !== id
                })
            )
        }else{
            setOpposesTopic(
                opposesTopic.filter((item) => {
                    return item.id !== id
                })
            )
        }
    }



    const handleSubmit = (e) => {
        e.preventDefault()
        const start_at = `${inputValue?.date} ${inputValue?.time} ${inputValue?.timezone}`

        if (category === "debate") {
            delete inputValue["date"]
            delete inputValue["time"]
            delete inputValue["timezone"]

            dispatch(
                createDebate({
                    ...inputValue,
                    "start_at": start_at,
                    "media": media,
                    "supporting": supportsTopic,
                    "opposing": opposesTopic
                })
            )
        } else {
            delete inputValue["duration"]
            
            dispatch(
                createDiscussion({
                    ...inputValue,
                    "media": media,
                })
            )
        }

        setInputValue({
            topic: "",
            description: "",
            author: user && user.id,
            date : "",
            time: '',
            timezone: "UTC"
        })
        setMedia([])
        setSupportsTopic({})
        setOpposesTopic([])
        setSearchedResult({
            supporters: [],
            opposers: []
        })
    }

    const draftPost = () => {
        console.log("draft")
        if (category === "debate") {
            // perform debate dispatch
        } else {
            // dispatch(
            //     createDiscussion({
            //         ...inputValue,
            //         "media" : media
            //     })
            // )
        }

        setInputValue({
            topic: "",
            description: "",
            author: user && user.id
        })
        setMedia([])
        setSupportsTopic({})
        setOpposesTopic([])
        setSearchedResult({
            supporters: [],
            opposers: []
        })
    }



    useEffect(() => {
        user && selectDebater(userDebateData, "supp")

        // eslint-disable-next-line 
    }, [])

    return (
        <div className='p-3 my-5'>
            <form encType='multipart/form-data' className='py-5' onSubmit={handleSubmit}>
                <div className='space-y-3 sm:px-[70px]'>
                    <PasswordResetInput
                        handleChange={handleTextChange}
                        value={inputValue.topic}
                        name="topic"
                        label="Question/Topic:"
                    />
                </div>

                <div className='my-3 sm:px-[70px]'>
                    <p>Brief Description:</p>
                    <textarea
                        value={inputValue.description}
                        name='description'
                        onChange={handleTextChange}
                        className='text-black p-4 my-2.5 w-full lg:min-w-[420px]
                             min-h-[140px] rounded-xl'>
                    </textarea>
                </div>

                <div className='my-5 sm:px-[70px]'>
                    <p>Allow Videos?</p>
                    <ButtonChoose
                        picked={videoYes}
                        onYes={() => {
                            setVideoYes(true)
                        }}
                        onNo={() => {
                            setVideoYes(false)
                        }}
                    />
                </div>

                <div className='my-5 sm:px-[70px]'>
                    <p>{videoYes ? "Add Photos/Videos" : "Add Photos"}</p>
                    <div className='flex sm:justify-start flex-wrap w-full'>
                        {media.length < 4 &&
                            <label
                                htmlFor='mediaUpload'
                                className='cursor-pointer w-[120px] my-3 rounded-xl h-[120px] 
                        flex justify-center items-center bg-nightModeDark_200'>
                                <FaPlus className='text-5xl' />
                            </label>}

                            <UploadedImagesDisplay removeMedia={removeMedia}  media={media}/>
                    </div>

                    <input
                        id="mediaUpload"
                        className='hidden'
                        type='file'
                        multiple
                        accept={videoYes ? "video/*, image/* " : "image/*"}
                        onChange={handleFIleInput} />

                    <p className='text-red-500'>{errorMsg}</p>
                </div>

                {category === "debate" &&
                    (<div>
                        <div className='my-5 sm:px-[70px]'>
                            <p>Select a side</p>
                            <p className='text-red-500'>{supportingErrorMessage}</p>
                            <p className='text-red-500'>{opposingErrorMessage}</p>
                            <ButtonChoose
                                picked={debater}
                                greenText="Supporting"
                                redText="Opposing"
                                onYes={() => {
                                    selectDebater(userDebateData, "supp")
                                    setDebater(true)
                                }}
                                onNo={() => {
                                    selectDebater(userDebateData, "opps")
                                    setDebater(false)
                                }}
                            />
                        </div>


                    
                    {supportsTopic.length < 3 &&
                        <div className='relative my-7 sm:px-[70px] space-y-1 w-full md:w-3/5'>
                        <p>Search Supporter(s) <span className='text-xs'>⚠️max. of 3</span></p>
                        <p className='text-red-500'>{opposingErrorMessage}</p>
                        <UnRequiredInput
                        handleChange={(e) => {
                            searchUser(e, "supp")
                        }}
                    />   
                    {(!closeSearchResult && searchedResult.supporters.length > 0) && (
                        <SearchBoxResult 
                        errorMsg={opposingErrorMessage}
                        results={searchedResult.supporters}
                         pickUser={selectDebater}
                         role="supp"
                         close={closeSearchBox}
                          />
                    )}
                    </div>
                    }




                        <div className='my-5 sm:px-[70px] space-y-3'>
                        <p>Duration</p>
                            <DebateDuration 
                            duration={inputValue.duration}
                             onFilterChange={handleTextChange} />
                        </div>

                        <div className='my-5 sm:px-[70px] space-y-3'>
                        <p>Start Time</p>
                        <DateTimePicker 
                        value={inputValue}
                        onChange={handleTextChange} />
                        </div>

                    {opposesTopic.length < 3 &&
                        <div className='relative my-7 sm:px-[70px] space-y-1 w-full md:w-3/5'>
                        <p>Search Opponent(s) <span className='text-xs'>⚠️max. of 3</span></p>
                        <p className='text-red-500'>{supportingErrorMessage}</p>
                        <UnRequiredInput
                        handleChange={(e) => {
                            searchUser(e, "opps")
                        }}
                    />   
                    {(!closeSearchResult && searchedResult.opposers.length > 0) && (
                        <SearchBoxResult 
                        errorMsg={supportingErrorMessage}
                        results={searchedResult.opposers}
                        pickUser={selectDebater}
                        role="opps"
                        close={closeSearchBox}
                         />
                    )}
                    </div>
                    }


                    <div className='my-5 sm:px-[70px]'>
                        <FinalDebaters 
                        teamA={supportsTopic}
                        teamB={opposesTopic}
                        removeDebater={removeDebater}
                        />
                    </div>

                    </div>)}




                <div className='space-y-4 sm:space-y-0 sm:space-x-4 mt-10 w-full sm:flex sm:justify-end'>
                    <button
                        type='button'
                        onClick={draftPost}
                        className='bg-nightModeDark_200 py-3 rounded-lg w-full sm:w-1/4'>
                        Save as Draft
                    </button>
                    <button className='bg-enhancedTeal py-3 rounded-lg w-full sm:w-1/4'>Post</button>
                </div>

            </form>
        </div>
    )
}

export default CreateForm